<!-- Loading -->
<ng-container *ngIf="(assignmentSubject | async) == null">
    <div class="text-center">
        <span><i class="fa-solid fa-spinner fa-spin"></i></span>
    </div>
</ng-container>

<!-- Finished Loading -->
<ng-container *ngIf="(assignmentSubject | async) != null">
    <div class="alert alert-secondary m-0" *ngIf="!(assignmentSubject | async)!.length">
        {{'assignments.emptyList' | transloco}}
    </div>

    <div class="d-flex flex-column gap-2 text-center" *ngIf="(assignmentSubject | async)!.length">
        <div *ngFor="let assignment of assignmentSubject | async" (click)="onAssignmentClick(assignment)"
            [attr.role]="itemRole">
            <div class="text-decoration-none text-dark w-100 shadow-sm p-3 bg-white d-block rounded"
                [ngClass]="{'border border-warning': assignment.isSearchingSubstitute!}">
                <div class="d-flex align-items-center gap-3 w-100">
                    <div class="flex-grow-1 overflow-hidden text-start">
                        <div class="pb-1" *ngIf="assignment.isSearchingSubstitute!">
                            <span class="badge bg-warning">{{'assignments.openToTakeover' | transloco}}</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <i class="fa-regular fa-calendar-days me-1"></i>
                            <span class="mb-0 fs-6 fw-lighter text-start text-nowrap">
                                {{ assignment.from | date: "EE dd.MM.yy | HH:mm" }}
                            </span>
                            <span> - </span>
                            <span class="mb-0 fs-6 fw-lighter text-start text-nowrap">
                                <ng-container
                                    *ngIf="isAssignmentOnSameDay(assignment.from, assignment.to) else fullDate">
                                    {{ assignment.to | date: "HH:mm" }}
                                </ng-container>
                                <ng-template #fullDate>
                                    {{ assignment.to | date: "EE dd.MM.yy | HH:mm" }}
                                </ng-template>
                            </span>
                        </div>
                        <div class="mb-0 fw-bold text-truncate">
                            {{ assignment.sportName }} | {{ assignment.title }}
                            <span *ngIf="assignment.level"> | {{assignment.level}}</span>
                        </div>
                        <p class="mb-0 fs-6">{{ assignment.room }}</p>
                    </div>
                    <div>
                        <ng-container *ngIf="eventType === assignmentEventType.RouteToDetails">
                            <i class="fa fa-chevron-right text-primary"></i>
                        </ng-container>
                        <ng-container *ngIf="eventType === assignmentEventType.Checkbox">
                            <input type="checkbox" [checked]="isAssignmentSelected(assignment.id)"
                                (click)="$event.stopPropagation(); toggleAssignmentSelection(assignment)" />
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>