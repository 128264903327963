import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { AssignmentEventType } from '../../shared/enums/AssignmentEventType.enums';
import { AssignmentStateService } from '../services/assignment-state.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentsFilterModalComponent } from '../assignments-filter-modal/assignments-filter-modal.component';
import { AssignmentsFilterStateService } from '../services/assignments-filter-state.service';
import { TranslocoService } from '@jsverse/transloco';
import { AssignmentsOverviewModel } from '../models/assignments-overview.model';
import { AssignmentExchangeModel } from '../models/assignment-exchange.model';
import { ExchangeAssignmentsModalComponent } from '../exchange-assignments-modal/exchange-assignments-modal.component';

@Component({
    templateUrl: './past-assignments.component.html',
    styleUrl: './past-assignments.component.scss',
    providers: [AssignmentsFilterStateService]
})
export class PastAssignmentsComponent implements OnInit {
    selectedAssignments: AssignmentsOverviewModel[] = [];
    assignmentEventType = AssignmentEventType;
    modalRef?: BsModalRef;

    constructor(
        public assignmentsStateService: AssignmentStateService,
        private assignmentsFilterStateService: AssignmentsFilterStateService,
        private headerStateService: HeaderStateService,
        private assignmentStateService: AssignmentStateService,
        private bsModalService: BsModalService,
        private translocoService: TranslocoService
    ) {
        this.headerStateService.setDetailHeader(
            this.translocoService.translate('main.header.pastAssignmentsTitle'),
            this.translocoService.translate('main.header.pastAssignmentsDescription'),
            '/assignments',
            'fa-solid fa-filter text-primary'
        );
    }

    ngOnInit(): void {
        let currentFilter = this.assignmentsStateService.pastAssignmentsFilterSubject.value;
        this.assignmentsStateService.loadPastAssignments(currentFilter);
        this.registerEvents();
    }

    // Opening the FilterModal
    public openFilterModal(): void {
        this.modalRef = this.bsModalService.show(AssignmentsFilterModalComponent);
        this.modalRef.content.assignmentsFilterSubject = this.assignmentsStateService.pastAssignmentsFilterSubject;
        this.modalRef.content.loadSavedFilters();
    }

    protected openSubstituteModal(): void {
        const exchangeAssignments = this.selectedAssignmentsToExchangeAssignmentsModel(this.selectedAssignments);
        const initialState = { exchangeAssignments: exchangeAssignments };
        const modalRef: BsModalRef = this.bsModalService.show(ExchangeAssignmentsModalComponent, { initialState });

        modalRef?.content.onSuccess.subscribe((success: boolean) => {
            if (success) {
                this.resetSelectedAssignments();
                let currentFilter = this.assignmentsStateService.exchangeAssignmentsFilterSubject.value;
                this.assignmentStateService.loadUpcomingAssignments(currentFilter);
            }
        });
    }

    protected onAssignmentSelected(assignments: AssignmentsOverviewModel[]): void {
        this.selectedAssignments = assignments;
    }

    // call only once in ngOnInit
    private registerEvents(): void {
        // Opening FilterModal in the Header
        this.headerStateService.getActionClickedEvent().subscribe((x) => {
            this.openFilterModal();
        })

        this.assignmentsFilterStateService.getIsAnyFilterDisplayedEvent().subscribe(x => {
            this.headerStateService.updateIsActiveStatusIndicatorDisplayed(x);
        });
    }

    private resetSelectedAssignments(): void {
        this.selectedAssignments = [];
    }

    private selectedAssignmentsToExchangeAssignmentsModel(selectedAssignments: AssignmentsOverviewModel[]): AssignmentExchangeModel[] {
        return selectedAssignments.map(x => {
            let model: AssignmentExchangeModel =
            {
                assignment: x,
                isSuccess: null,
                errorMessage: null
            };

            return model;
        });
    }
}
