import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { AssignmentEventType } from '../../shared/enums/AssignmentEventType.enums';
import { AssignmentStateService } from '../services/assignment-state.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentsFilterModalComponent } from '../assignments-filter-modal/assignments-filter-modal.component';
import { AssignmentsFilterStateService } from '../services/assignments-filter-state.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
    templateUrl: './my-assignments.component.html',
    styleUrl: './my-assignments.component.scss',
    providers: [AssignmentsFilterStateService]
})
export class MyAssignmentsComponent implements OnInit {

    modalRef?: BsModalRef;

    protected assignmentEventTypes = AssignmentEventType;

    constructor(
        public assignmentsStateService: AssignmentStateService,
        private assignmentsFilterStateService: AssignmentsFilterStateService,
        private headerStateService: HeaderStateService,
        private bsModalService: BsModalService,
        private translocoService: TranslocoService
    ) {
        this.headerStateService.setDetailHeader(
            this.translocoService.translate('main.header.myAssignmentsTitle'),
            this.translocoService.translate('main.header.myAssignmentsDescription'),
            '/assignments',
            'fa-solid fa-filter text-primary'
        );
    }

    ngOnInit(): void {
        this.registerEvents();

        let currentFilter = this.assignmentsStateService.upcomingAssignmentsFilterSubject.value;
        this.assignmentsStateService.loadUpcomingAssignments(currentFilter);
    }

    // Opening the FilterModal
    public openModal(): void {
        this.modalRef = this.bsModalService.show(AssignmentsFilterModalComponent);
        this.modalRef.content.assignmentsFilterSubject = this.assignmentsStateService.upcomingAssignmentsFilterSubject;
        this.modalRef.content.loadSavedFilters();
    }

    // call only once in ngOnInit
    private registerEvents(): void {

        // Opening FilterModal in the Header
        this.headerStateService.getActionClickedEvent().subscribe((x) => {
            this.openModal();
        });

        this.assignmentsFilterStateService.getIsAnyFilterDisplayedEvent().subscribe(x => {
            this.headerStateService.updateIsActiveStatusIndicatorDisplayed(x);
        });
    }
}
