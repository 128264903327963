import { NgModule, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, ActivatedRouteSnapshot } from '@angular/router';
import { EmptyComponent } from './shared/empty/empty.component';

export const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const sharedRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider,
    },
    component: EmptyComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(sharedRoutes),
  ],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: goToLink,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export function goToLink(route: ActivatedRouteSnapshot) {
  const externalUrl = route.paramMap.get('externalUrl')!;
  window.open(externalUrl, '_self');
}
