import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { KnowHowMessageModalComponent } from '../know-how-message-modal/know-how-message-modal.component';
import { SportService } from '../../sports/services/sports.service';
import { SportTrainersModel } from '../../sports/models/sport-trainers.model';

@Component({
    selector: 'app-know-how-contact-info',
    templateUrl: './know-how-contact-info.component.html',
    styleUrl: './know-how-contact-info.component.scss',
})
export class KnowHowContactInfoComponent implements OnInit {

    sportTrainers: SportTrainersModel[] | null = null;
    toggler: boolean = false;

    constructor(
        private bsModalRef: BsModalRef,
        private bsModalService: BsModalService,
        private sportService: SportService
    ) { }

    ngOnInit(): void {
        this.loadTrainerSportsTrainers();
    }

    protected sendMessageToSelected(sportTrainers: SportTrainersModel): void {
        this.openMessageModal(false, sportTrainers);
    }
    protected toggle(): void {
        this.toggler = !this.toggler;
    }

    protected sendMessageToAll(sportTrainers: SportTrainersModel): void {

        this.openMessageModal(true, sportTrainers);
    }

    protected hasSelectedTrainers(sportType: SportTrainersModel): boolean {
        return sportType.trainerContacts.some(trainer => trainer.isSelected);
    }

    private openMessageModal(contactAll: boolean, sportTrainers: SportTrainersModel): void {
        this.bsModalRef = this.bsModalService.show(
            KnowHowMessageModalComponent, {
            ignoreBackdropClick: true,
            initialState: {
                contactAll: contactAll,
                sportTrainers: sportTrainers,
            }
        })
    }

    private loadTrainerSportsTrainers(): void {
        this.sportService.getSportTrainer().subscribe({
            next: (sportTrainers) => {
                if (sportTrainers) {
                    sportTrainers = sportTrainers.sort((a, b) => a.sportName.localeCompare(b.sportName));
                    sportTrainers.forEach((sportTrainer) => {
                        if (sportTrainer.trainerAdmins) {
                            sportTrainer.trainerAdmins = sportTrainer.trainerAdmins.sort((a, b) => (a.firstName + b.lastName).localeCompare((b.firstName + b.lastName)));
                            sportTrainer.trainerContacts = sportTrainer.trainerContacts.sort((a, b) => (a.firstName + b.lastName).localeCompare((b.firstName + b.lastName)));
                        }
                    });
                }
                this.sportTrainers = sportTrainers;
            },
        });
    }

}
