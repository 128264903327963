<pmt-action-modal [title]="'assignments.information.cancel' | transloco" [submitButtonIconCode]="''"
    [submitButtonText]="'modal.sendButton' | transloco" (submitted)="cancelAssignment()" class="custom-modal-limiter">
    <form [formGroup]="cancellationForm">
        <div class="d-flex flex-column gap-3">
            <div class="alert alert-danger m-0">
                <p class=" p-0 m-0">{{'assignments.information.cancellationAlert' | transloco }}</p>
            </div>
            <div>
                <label for="cancellationReason">
                    {{ "assignments.information.cancellationReason" | transloco }}
                </label>
                <textarea id="cancellationReason" class="form-control" formControlName="cancellationReason"
                    rows="3"></textarea>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="isCancellationConfirmed"
                    id="isCancellationConfirmed">
                <label class="form-check-label" for="isCancellationConfirmed">
                    {{'assignments.information.cancellationConfirm' | transloco }}
                </label>
            </div>
            <ng-container *ngIf="isError">
                <p class="text-danger p-0 m-0">{{'generalError' | transloco}}</p>
            </ng-container>
        </div>

    </form>
</pmt-action-modal>