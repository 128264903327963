import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { AssignmentStateService } from '../services/assignment-state.service';
import { AssignmentEventType } from '../../shared/enums/AssignmentEventType.enums';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentsOverviewModel } from '../models/assignments-overview.model';
import { OpenAssignmentsModalComponent } from '../open-assignments-modal/open-assignments-modal.component';
import { AssignmentsFilterModalComponent } from '../assignments-filter-modal/assignments-filter-modal.component';
import { AssignmentsFilterStateService } from '../services/assignments-filter-state.service';
import { TranslocoService } from '@jsverse/transloco';
import { AssignmentTakeoverModel } from '../models/assignment-takeover.model';

@Component({
    templateUrl: './open-assignments.component.html',
    styleUrl: './open-assignments.component.scss',
    providers: [AssignmentsFilterStateService]
})
export class OpenAssignmentsComponent implements OnInit {
    selectedAssignments: AssignmentsOverviewModel[] = [];
    assignmentEventType = AssignmentEventType;
    modalRef?: BsModalRef;

    constructor(
        public assignmentsStateService: AssignmentStateService,
        private assignmentsFilterStateService: AssignmentsFilterStateService,
        private headerStateService: HeaderStateService,
        private bsModalService: BsModalService,
        public assignmentStateService: AssignmentStateService,
        private translocoService: TranslocoService
    ) {
        this.headerStateService.setDetailHeader(
            this.translocoService.translate('main.header.openAssignmentsTitle'),
            this.translocoService.translate('main.header.openAssignmentsDescription'),
            '/assignments',
            'fa-solid fa-filter text-primary'
        );
    }

    ngOnInit(): void {
        let currentFilter = this.assignmentsStateService.openAssignmentsFilterSubject.value;
        this.assignmentsStateService.loadOpenAssignments(currentFilter);
        this.registerEvents();
    }

    public openTakeoverModal(): void {

        const takeoverAssignments = this.selectedAssignmentsToAssignmentTakeoverModels(this.selectedAssignments);

        const initialState = { takeoverAssignments: takeoverAssignments };
        this.modalRef = this.bsModalService.show(OpenAssignmentsModalComponent, { initialState });
        this.modalRef?.content.onSuccess.subscribe((success: boolean) => {
            if (success) {
                let currentFilter = this.assignmentsStateService.openAssignmentsFilterSubject.value;
                this.assignmentStateService.loadOpenAssignments(currentFilter);
                this.resetSelectedAssignments();
            }
        })
    }

    public onAssignmentSelected(assignments: AssignmentsOverviewModel[]): void {
        this.selectedAssignments = assignments;
    }

    public openFilterModal(): void {
        this.modalRef = this.bsModalService.show(AssignmentsFilterModalComponent);
        this.modalRef.content.assignmentsFilterSubject = this.assignmentsStateService.openAssignmentsFilterSubject;
        this.modalRef.content.loadSavedFilters();
    }

    private resetSelectedAssignments(): void {
        this.selectedAssignments = [];
    }

    private selectedAssignmentsToAssignmentTakeoverModels(selectedAssignments: AssignmentsOverviewModel[]): AssignmentTakeoverModel[] {

        let exchangeModel = selectedAssignments.map(x => {
            let model: AssignmentTakeoverModel =
            {
                assignment: x,
                isSuccess: null,
                errorMessage: null
            };

            return model;
        });

        return exchangeModel;
    }

    // call only once in ngOnInit
    private registerEvents(): void {
        // Opening FilterModal in the Header
        this.headerStateService.getActionClickedEvent().subscribe((x) => {
            this.openFilterModal();
        });

        this.assignmentsFilterStateService.getIsAnyFilterDisplayedEvent().subscribe(x => {
            this.headerStateService.updateIsActiveStatusIndicatorDisplayed(x);
        });
    }
}
