import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserAuthorizationService } from '../../security/user-authorization.service';
import { KnowHowMessageTrainerPostModel } from '../models/know-how-message-trainer-post.model';
import { SportService } from '../../sports/services/sports.service';
import { SportTrainersModel } from '../../sports/models/sport-trainers.model';
import { SportTrainerContactModel } from '../../sports/models/sport-trainer-contact.model';
import { ApplicationUser } from '../../security/application-user.model';

@Component({
    selector: 'app-know-how-message-modal',
    templateUrl: './know-how-message-modal.component.html',
    styleUrl: './know-how-message-modal.component.scss'
})
export class KnowHowMessageModalComponent implements OnInit {

    isLoading: boolean = false;
    contactAll!: boolean;

    messageForm!: FormGroup;
    sportTrainers!: SportTrainersModel;

    trainers: SportTrainerContactModel[] | null = null;

    constructor(
        protected sportService: SportService,
        private formBuilder: FormBuilder,
        private bsModalRef: BsModalRef,
        private authService: UserAuthorizationService,
    ) { }

    ngOnInit(): void {
        this.initForm();
        this.setTrainers();
    }

    get sender(): ApplicationUser | null {
        let sender = this.authService.getUser();
        return sender ?? null;
    }

    initForm(): void {
        this.messageForm = this.formBuilder.group({
            trainerMessage: ["", Validators.required],
            isSendCopy: [false],
            subject: ["", Validators.required]
        })
    }

    onSubmit(): void {
        if (this.isLoading) return;
        if (!this.messageForm.valid) return;
        this.isLoading = true;

        var trainerIds = this.sportTrainers.trainerContacts.filter(x => this.contactAll || x.isSelected).flat().map(x => x.id);

        const postModel: KnowHowMessageTrainerPostModel = {
            selectedTrainerIds: trainerIds,
            message: this.messageForm.get('trainerMessage')?.value,
            subject: this.messageForm.get('subject')?.value,
            isSendCopyActive: this.messageForm.get('isSendCopy')?.value,
            sportId: this.sportTrainers.sportId,
        }

        this.sendMessage(postModel);
    }

    private setTrainers(): void {
        this.trainers = this.sportTrainers.trainerContacts.filter(x => this.contactAll || x.isSelected);
    }

    private sendMessage(postData: KnowHowMessageTrainerPostModel): void {
        this.sportService.sendMessageToTrainers(postData).subscribe({
            next: (response) => {
                this.bsModalRef.hide();
            },
            error: (error) => {
                this.isLoading = false;
            }
        });
    }
}
