import { Component, Input, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssignmentsService } from '../../../../services/assignments.service';
import { AssignmentCancellationPostModel } from '../../../models/assignment-cancellation-post.model';

@Component({
    selector: 'app-assignment-cancel-modal',
    templateUrl: './assignment-cancel-modal.component.html',
    styleUrl: './assignment-cancel-modal.component.scss'
})
export class AssignmentCancelModalComponent implements OnInit {
    @Input() assignmentId!: number;
    isError: boolean = false;
    cancellationForm!: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private bsModalRef: BsModalRef,
        private assignmentService: AssignmentsService
    ) { }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.cancellationForm = this.formBuilder.group({
            cancellationReason: ['', Validators.required],
            isCancellationConfirmed: [false, Validators.requiredTrue]
        })
    }

    protected cancelAssignment(): void {

        this.cancellationForm.markAllAsTouched();
        if (!this.cancellationForm.valid) return;

        const assignmentCanceled: boolean = this.cancellationForm.value.isCancellationConfirmed;

        const cancellationModel: AssignmentCancellationPostModel = {
            cancellationReason: this.cancellationForm.value.cancellationReason,
        };

        if (assignmentCanceled) {
            this.assignmentService.cancelAssignment(this.assignmentId, cancellationModel).subscribe({
                next: (response) => {
                    if (response.isSuccess) this.bsModalRef.hide();
                },
                error: (err) => {
                    this.isError = true;
                }
            });
        }
    }

}
