import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentAttendanceModalComponent } from '../assignment-attendance-modal/assignment-attendance-modal.component';
import { AssignmentAttendanceStateService } from '../../services/assignment-attendance-state.service';

@Component({
  selector: 'app-assignment-attendance',
  templateUrl: './assignment-attendance.component.html',
  styleUrl: './assignment-attendance.component.scss'
})
export class AssignmentAttendanceComponent {
  @Input() assignmentId!: number;

  constructor(
    private modalRef: BsModalRef,
    private bsModalService: BsModalService,
    protected assignmentAttendanceStateService: AssignmentAttendanceStateService,
  ) { }

  public openModal(): void {
    this.modalRef = this.bsModalService.show(AssignmentAttendanceModalComponent, {
      initialState: {
        assignmentId: this.assignmentId
      }
    });
  }
}
