import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AssignmentDetailsModel } from '../models/assignment-details.model';
import { AssignmentDetailsInstructorsModel } from '../models/assignment-details-instructors.model';
import { AssignmentDetailsDateModel } from '../models/assignment-details-date.model';

@Injectable({
    providedIn: 'root',
})
export class AssignmentDetailsService {

    constructor(private httpClient: HttpClient) { }

    getAssignmentDetails(assignmentId: number): Observable<AssignmentDetailsModel> {
        return this.httpClient.get<AssignmentDetailsModel>(`${environment.api}/assignments/${assignmentId}`);
    }

    getAssignmentDetailInstructors(assignmentId: number): Observable<AssignmentDetailsInstructorsModel[]> {
        return this.httpClient.get<AssignmentDetailsInstructorsModel[]>(`${environment.api}/assignments/${assignmentId}/instructors`);
    }

    getAssignmentDetailDates(assignmentId: number): Observable<AssignmentDetailsDateModel[]> {
        return this.httpClient.get<AssignmentDetailsDateModel[]>(`${environment.api}/assignments/${assignmentId}/dates`);
    }
}
