import { Roles } from './roles.enum';

export class ApplicationUser {
    nameid?: string;
    unique_name?: string;
    given_name?: string;
    family_name?: string;
    email?: string;
    asvzId?: number;
    imId?: number;
    roles?: Array<string>;

    public isAuthenticated() {
        return (this.nameid != null);
    }

    public isAdmin(): boolean {
        if (this.hasRole(Roles.Admin)) return true;
        if (this.hasRole(Roles.Office)) return true;
        return false;
    }

    public isStaff(): boolean {
        if (this.hasRole(Roles.Hausdienst)) return true;
        if (this.hasRole(Roles.Trainingsleiter)) return true;
        return false;
    }

    public isStaffOrAdmin(): boolean {
        if (this.isAdmin()) return true;
        if (this.isStaff()) return true;
        return false;
    }

    private hasRole(role: Roles) {
        if (!this.roles) {
            return false;
        }
        return this.roles.indexOf(role.toString()) >= 0;
    }
}

