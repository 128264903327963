<!-- Loading -->
<ng-container *ngIf="(assignmentParticipantStateService.assignmentParticipantsSubject| async) == null">
    <div class="text-center">
        <span><i class="fa-solid fa-spinner fa-spin"></i></span>
    </div>
</ng-container>

<ng-container
    *ngIf="(assignmentParticipantStateService.assignmentParticipantsSubject | async) as assignmentParticipants">
    <div class="d-flex flex-column gap-4" *ngIf="assignmentParticipants.length">
        <app-assignment-participants-list></app-assignment-participants-list>
        <app-assignment-contact-participants [assignmentId]="assignmentId"></app-assignment-contact-participants>
    </div>
    <div class="alert alert-secondary" *ngIf="!assignmentParticipants.length">
        {{'assignments.participants.noParticipants' | transloco}}
    </div>
</ng-container>