import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserAuthorizationService } from '../user-authorization.service';
import { AuthGuard } from './auth-guard.service';

@Injectable({
    providedIn: 'root'
})
export class StaffAuthGuard extends AuthGuard implements CanActivate {
    constructor(private router: Router, authService: UserAuthorizationService) {
        super(authService);
    }

    public override async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        var isLoggedIn = await super.ensureUserIsLoggedIn(state);
        if (!isLoggedIn) return false;

        return this.ensureUserIsStaff();
    }

    async ensureUserIsStaff(): Promise<boolean> {
        var user = this.authService.getUser();
        if (user.isStaffOrAdmin()) return true;

        // redirect to home
        this.router.navigateByUrl('/');
        return false;
    }
}