import { NgModule } from '@angular/core';
import { DatePipe } from "@angular/common";
import { SharedModule } from '../shared/shared.module';
import { KnowHowComponent } from './know-how.component';
import { KnowHowDocumentsComponent } from './know-how-documents/know-how-documents.component';
import { KnowHowLinksComponent } from './know-how-links/know-how-links.component';
import { KnowHowContactInfoComponent } from './know-how-contact-info/know-how-contact-info.component';
import { KnowHowMessageModalComponent } from './know-how-message-modal/know-how-message-modal.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        KnowHowComponent,
        KnowHowDocumentsComponent,
        KnowHowLinksComponent,
        KnowHowContactInfoComponent,
        KnowHowMessageModalComponent,
    ],
    exports: [
    ],
    providers: [
        DatePipe
    ]
})
export class KnowHowModule { }
