<ng-container *ngIf="newsModel">
    <div class="d-flex flex-column gap-4">
        <div class="card bg-white border border-rounded shadow-sm">
            <div class="card-header p-5">
                <div class="d-flex flex-column">
                    <h3 class="fw-bolder fs mb-0">{{ newsModel.title }}</h3>
                    <p class="mb-0">{{ newsModel.publicationFrom | date: "dd.MM.yyyy" }}</p>
                </div>
            </div>
        </div>
        <markdown class="variable-binding" [data]="newsModel.content"></markdown>
    </div>
</ng-container>