import { Component, Input, OnInit } from '@angular/core';
import { AssignmentDetailsService } from '../../services/assignment-details.service';
import { AssignmentDetailsInstructorsModel } from '../../models/assignment-details-instructors.model';

@Component({
    selector: 'app-assignment-instructors',
    templateUrl: './assignment-instructors.component.html',
    styleUrl: './assignment-instructors.component.scss'
})
export class AssignmentInstructorsComponent implements OnInit {
    @Input({ required: true }) assignmentId!: number;

    instructors: AssignmentDetailsInstructorsModel[] | null = null;

    constructor(private assignmentDetailsService: AssignmentDetailsService) { }

    ngOnInit(): void {
        this.loadInstructors();
    }

    private loadInstructors(): void {
        this.assignmentDetailsService.getAssignmentDetailInstructors(this.assignmentId).subscribe({
            next: (instructors) => {
                instructors = instructors.sort((a, b) => a.lastName.localeCompare(b.lastName));
                this.instructors = instructors;
            }
        });
    }
}
