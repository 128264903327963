import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ImpersonationPersonModel } from '../models/impersonation-person.model';

@Injectable({
    providedIn: 'root'
})

export class ImpersonationStateService {

    public isImpersonationActive = new BehaviorSubject<boolean>(false);
    public activeImpersonationPerson = new BehaviorSubject<ImpersonationPersonModel | null | undefined>(undefined);
    public activeImpersonationUserId = new BehaviorSubject<number | null | undefined>(undefined);
    public impersonationSubject = new BehaviorSubject<ImpersonationPersonModel | null>(null);

    constructor() {
        this.registerEvents();
    }

    private registerEvents(): void {
        this.activeImpersonationPerson.subscribe({
            next: (x: ImpersonationPersonModel | null | undefined) => {
                if (x === null || x === undefined) {
                    this.isImpersonationActive.next(false);
                    return;
                }
                this.isImpersonationActive.next(true);
            }
        })
    }
}
