<!-- Loading -->
<ng-container *ngIf="instructors == null">
    <div class="text-center">
        <span><i class="fa-solid fa-spinner fa-spin"></i></span>
    </div>
</ng-container>

<ng-container *ngIf="instructors != null">
    <div *ngFor="let instructor of instructors">
        {{instructor.firstName}} {{instructor.lastName}}
        <span class="badge bg-secondary">{{instructor.functionName}}</span>
    </div>
</ng-container>