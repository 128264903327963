<pmt-action-modal [title]="'assignments.openAssignments.modalTitle' | transloco" [onlyCancel]="isCloseOnly"
    [cancelButtonText]="isCloseOnly ? ('modal.closeButton' | transloco) : ('modal.cancelButton' | transloco)"
    [submitButtonText]="'assignments.openAssignments.submitButton' | transloco" [submitButtonIconCode]="''"
    (submitted)="setTakeoverModel()" class="custom-modal-limiter">
    <div class="container d-flex flex-column gap-3">
        <div *ngFor="let takeoverAssignment of takeoverAssignments; let i = index">
            <div class="text-decoration-none text-dark w-100 shadow-sm p-3 d-block" [ngClass]="{
          'border border-2 border-success': takeoverAssignment.isSuccess == true,
          'border border-2 border-danger': takeoverAssignment.isSuccess == false
        }">
                <div class="d-flex align-items-center gap-1 w-100">
                    <div class="flex-grow-1 overflow-hidden text-start">
                        <div class="d-flex align-items-center">
                            <i class="fa-regular fa-calendar-days me-1"></i>
                            <span class="mb-0 fs-6 fw-lighter text-start text-nowrap">
                                {{ takeoverAssignment.assignment.from | date: "EE dd.MM.yy | HH:mm" }}
                            </span>
                            <span> - </span>
                            <span class="mb-0 fs-6 fw-lighter text-start text-nowrap">
                                <ng-container
                                    *ngIf="isAssignmentOnSameDay(takeoverAssignment.assignment.from, takeoverAssignment.assignment.to); else fullDate">
                                    {{ takeoverAssignment.assignment.to | date: "HH:mm" }}
                                </ng-container>
                                <ng-template #fullDate>
                                    {{ takeoverAssignment.assignment.to | date: "EE dd.MM.yy | HH:mm" }}
                                </ng-template>
                            </span>
                        </div>
                        <div class="mb-0 fw-bold text-truncate">
                            {{ takeoverAssignment.assignment.sportName }} | {{ takeoverAssignment.assignment.title }}
                        </div>
                        <p class="mb-0 fs-6">{{ takeoverAssignment.assignment.room }}</p>
                    </div>
                    <div></div>
                </div>
                <!-- Individual Response Message -->
                <div class="text-danger" *ngIf="takeoverAssignment.isSuccess == false">
                    {{ takeoverAssignment.errorMessage }}
                </div>
            </div>
        </div>
    </div>
</pmt-action-modal>