import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { AssignmentDetailsStateService } from './services/assignment-details-state.service';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { environment } from '../../../environments/environment';
import { BaseComponent } from '@premotec/pmt-essentials';
import { AssignmentDetailsService } from './services/assignment-details.service';
import { AssignmentDetailsDateModel } from './models/assignment-details-date.model';
import { AssignmentParticipantsStateService } from './services/assignments-participants-state.service';

@Component({
    selector: 'app-assigment-details',
    templateUrl: './assignment-details.component.html',
    styleUrl: './assignment-details.component.scss'
})
export class AssigmentDetailsComponent extends BaseComponent implements OnInit {
    assignmentId!: number;
    informationLink: string | null = null;

    assignmentDates: AssignmentDetailsDateModel[] | null = null;

    constructor(
        private route: ActivatedRoute,
        private headerStateService: HeaderStateService,
        private assignmentDetailsService: AssignmentDetailsService,
        private assignmenParticipantStateService: AssignmentParticipantsStateService,
        protected assignmentDetailsStateService: AssignmentDetailsStateService,
        private translocoService: TranslocoService
    ) {
        super();
        this.setHeaderInformation();
    }

    ngOnInit(): void {
        this.registerEvents();
        this.route.params.subscribe(params => {
            this.assignmentId = params['id'];
            this.assignmentDetailsStateService.loadAssingmentDetails(this.assignmentId);
            this.assignmenParticipantStateService.loadAssignmentParticipants(this.assignmentId);
            this.loadAssignmentDates();
        });
    }

    private setOnlineDeskUrl(eventLessonId: number, isLesson: boolean): void {
        const eventLessonUrl = isLesson ? 'lessons' : 'events';
        this.informationLink = `${environment.onlineDeskUrl}/${eventLessonUrl}/${eventLessonId}`
    }

    private setHeaderInformation(): void {
        let onlineDeskUrlIcon = (this.informationLink) ? 'fa-solid fa-arrow-up-right-from-square text-primary' : 'fa-solid fa-spinner fa-spin';

        this.headerStateService.setDetailHeader(
            this.translocoService.translate('main.header.assignmentDetailsTitle'),
            this.translocoService.translate('main.header.assignmentDetailsDescription'),
            '/assignments/my',
            onlineDeskUrlIcon);
    }

    private registerEvents(): void {

        this.assignmentDetailsStateService.assignmentDetails.subscribe((x) => {
            if (x == null) return;

            this.setOnlineDeskUrl(x.parentEventLessonId ?? x.eventLessonId, x.isLesson);
            this.setHeaderInformation();
        });

        // Header icon action
        this.headerStateService.getActionClickedEvent().subscribe((x) => {
            // Navigate to link logic
            if (!this.informationLink) return;
            window.open(this.informationLink!, '_blank');
        });
    }

    private loadAssignmentDates(): void {
        this.assignmentDetailsService.getAssignmentDetailDates(this.assignmentId).subscribe({
            next: (dates) => {
                this.assignmentDates = dates;
            }
        });
    }
}
