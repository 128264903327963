import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderStateService } from '../main/headers/services/header-state.service';
import { UserAuthorizationService } from '../security/user-authorization.service';
import { AssignmentsOverviewModel } from '../assignments/models/assignments-overview.model';
import { AssignmentEventType } from '../shared/enums/AssignmentEventType.enums';
import { AssignmentStateService } from '../assignments/services/assignment-state.service';
import { SponsorsStateService } from '../shared/components/sponsors/services/sponsors-state.service';
import { Router } from '@angular/router';
import { AssignmentTakeoverModel } from '../assignments/models/assignment-takeover.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OpenAssignmentsModalComponent } from '../assignments/open-assignments-modal/open-assignments-modal.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
    assigmentsOverviewModel?: AssignmentsOverviewModel[] = [];
    isLoggedIn: boolean | null = null;
    isStaffOrAdmin: boolean | null = null;

    protected assignmentEventTypes = AssignmentEventType;

    constructor(
        private router: Router,
        private bsModalService: BsModalService,
        private headerStateService: HeaderStateService,
        private sponsorsStateService: SponsorsStateService,
        private authService: UserAuthorizationService,
        public assignmentsStateService: AssignmentStateService,
    ) {
        this.headerStateService.showDefaultHeader();
    }
    ngOnDestroy(): void {
        this.sponsorsStateService.hideSponsorFooter();
    }

    ngOnInit(): void {
        this.authService.isLoggedIn().then((x) => {
            this.isLoggedIn = x;

            if (this.isLoggedIn) {
                this.isStaffOrAdmin = this.authService.getUser().isStaffOrAdmin();
                this.sponsorsStateService.hideSponsorFooter();

                if (!this.isStaffOrAdmin) return;
                this.assignmentsStateService.loadtopThreeNextAssignments();
                this.assignmentsStateService.loadtopThreeOpenAssignments();
            } else {
                this.sponsorsStateService.showSponsorFooter();
            }
            ;
        });
    }

    login() {
        this.authService.startAuthentication();
    }


    protected openAssignmentClicked(assignment: AssignmentsOverviewModel): void {

        const takeoverAssignment: AssignmentTakeoverModel = {
            assignment: assignment,
            isSuccess: null,
            errorMessage: null
        }

        const takeoverAssignments = [takeoverAssignment];

        const initialState = { takeoverAssignments: takeoverAssignments };
        let modalRef = this.bsModalService.show(OpenAssignmentsModalComponent, { initialState });
        modalRef!.content!.onSuccess.subscribe((success: boolean) => {
            if (success) {
                this.assignmentsStateService.loadtopThreeNextAssignments();
                this.assignmentsStateService.loadtopThreeOpenAssignments();
            }
        })
    }
}
