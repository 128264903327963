import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HeaderStateService } from '../services/header-state.service';

@Component({
    selector: 'app-detail-header',
    templateUrl: './detail-header.component.html',
    styleUrls: ['./detail-header.component.scss']
})
export class DetailHeaderComponent implements OnInit {

    isTestEnvironment = environment.staging == true;
    isDevEnvironment = environment.production == false && environment.staging == false;
    isProdEnvironment = environment.production == true;

    constructor(
        public headerStateService: HeaderStateService,
    ) {
    }

    ngOnInit(): void {
    }

    actionButtonClicked() {
        this.headerStateService.triggerActionClickedEvent();
    }
}
