<div class="d-flex flex-column gap-3">
    <app-page-divider [text]="'assignments.myAssignmentsTitle' | transloco"
        [icon]="'fa-solid fa-calendar-days'"></app-page-divider>
    <div class="d-flex flex-column gap-3">
        <a [routerLink]="'my'" class="text-decoration-none text-dark">
            <div class="shadow-sm p-3 bg-body rounded">
                <div class="d-flex align-items-center justify-content-between">
                    <span> {{ "assignments.futureAssignments.title" | transloco }} </span>
                    <i class="fa fa-chevron-right text-primary"></i>
                </div>
            </div>
        </a>

        <a [routerLink]="'exchange'" class="text-decoration-none text-dark">
            <div class="shadow-sm p-3 bg-body rounded">
                <div class="d-flex align-items-center justify-content-between">
                    <span> {{ "assignments.exchangeAssignments.title" | transloco }} </span>
                    <i class="fa fa-chevron-right text-primary"></i>
                </div>
            </div>
        </a>

        <a [routerLink]="'past'" class="text-decoration-none text-dark">
            <div class="shadow-sm p-3 bg-body rounded">
                <div class="d-flex align-items-center justify-content-between">
                    <span> {{ "assignments.pastAssignments.title" | transloco }} </span>
                    <i class="fa fa-chevron-right text-primary"></i>
                </div>
            </div>
        </a>

        <a [routerLink]="'billed'" class="text-decoration-none text-dark">
            <div class="shadow-sm p-3 bg-body rounded">
                <div class="d-flex align-items-center justify-content-between">
                    <span> {{ "assignments.billedAssignments.title" | transloco }} </span>
                    <i class="fa fa-chevron-right text-primary"></i>
                </div>
            </div>
        </a>

    </div>

    <app-page-divider [text]="'assignments.openAssignmentsTitle' | transloco"
        [icon]="'fa-solid fa-calendar-days'"></app-page-divider>
    <div class="d-flex flex-column gap-3">
        <a [routerLink]="'open'" class="text-decoration-none text-dark">
            <div class="shadow-sm p-3 bg-body rounded">
                <div class="d-flex align-items-center justify-content-between">
                    <span> {{ "assignments.openAssignments.title" | transloco }} </span>
                    <i class="fa fa-chevron-right text-primary"></i>
                </div>
            </div>
        </a>
    </div>
</div>