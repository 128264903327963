import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { KnowHowMessageTrainerPostModel } from '../../know-how/models/know-how-message-trainer-post.model';
import { SportTrainerModel } from '../models/sport-trainer.model';
import { SportTrainersModel } from '../models/sport-trainers.model';


@Injectable({
    providedIn: 'root',
})
export class SportService {
    constructor(private httpClient: HttpClient) { }

    getTrainers(sportIds: number[]): Observable<SportTrainerModel[]> {
        const params: { [key: string]: string } = {};
        if (sportIds) params['sportIds'] = sportIds.join(',');

        return this.httpClient.get<SportTrainerModel[]>(`${environment.api}/sports/substituteTrainers`, { params });
    }

    getSportTrainer(): Observable<SportTrainersModel[]> {
        return this.httpClient.get<SportTrainersModel[]>(
            `${environment.api}/sports/trainers/contacts`
        );
    }

    sendMessageToTrainers(postData: KnowHowMessageTrainerPostModel): Observable<KnowHowMessageTrainerPostModel> {
        return this.httpClient.post<KnowHowMessageTrainerPostModel>(
            `${environment.api}/sports/trainers/messages`,
            postData
        );
    }
}