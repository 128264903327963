<div class="header bg-white text-dark text-center p-3 fixed-top">
    <div class="container">
        <div class="d-flex">
            <div>
                <div *ngIf="isLoggedIn != null && isLoggedIn">
                    <p class="p-0 m-0 text-start header-text" (dblclick)="navigateToImpersonation()">
                        {{ greetingTextKey | transloco }}
                        <br /><b>{{ username }}</b>
                    </p>
                </div>
            </div>
            <div class="flex-grow-1"></div>
            <div>
                <img class="logo" src="/assets/images/logos/asvz/logo-white-on-blue.jpg" />
            </div>
        </div>
    </div>
</div>