import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collapsible-card',
  templateUrl: './collapsible-card.component.html',
  styleUrl: './collapsible-card.component.scss'
})
export class CollapsibleCardComponent {
  isOpen: boolean = false;
  @Input() cardTitle: string = '';

  protected toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
