<pmt-action-modal [title]="'assignments.communication.editUpdate' | transloco"
    [cancelButtonText]="'modal.cancelButton' | transloco" submitButtonIconCode="''" (submitted)="saveForm()"
    class="custom-modal-limiter">
    <!-- Loading -->
    <ng-container *ngIf="!updateForm">
        <div class="text-center">
            <span>
                <i class="fa-solid fa-spinner fa-spin"></i>
            </span>
        </div>
    </ng-container>

    <!-- Loading Finished -->
    <ng-container *ngIf="updateForm">
        <div [ngClass]="{'border border-2 border-danger': updateResponse?.isSuccess == false}">
            <form [formGroup]="updateForm">
                <textarea class="form-control" formControlName="update" rows="3" [disabled]="isLoading"></textarea>
            </form>
        </div>
        <ng-container *ngIf="!updateResponse?.isSuccess">
            <p class=" text-danger">{{updateResponse?.errorMessage}}</p>
        </ng-container>
    </ng-container>
</pmt-action-modal>