<div class="p-5">
  <div class="container d-flex flex-column gap-3">
    <!-- Title -->
    <p class="text-center fs-2 fw-normal">{{ "home.sponsorTitle" | transloco }}</p>
    <!-- Sponsors Flexbox -->
    <div class="d-flex flex-column flex-md-row align-items-center justify-content-center">
      <img 
        class="img-fluid p-2 w-75 w-lg-50" 
        src="/assets/images/logos/sponsors/ASVZ-Sponsors.png" 
        alt="Asvz Sponsoren">
    </div>
  </div>
</div>
