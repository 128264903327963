import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AssignmentsFilterModel } from '../models/assignments-filter.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { AssignmentStateService } from '../services/assignment-state.service';
import { AssignmentsService } from '../services/assignments.service';
import { AssignmentsFilterOptionsModel } from '../models/assignments-filter-options.model';
import { AssignmentsFilterLevelModel } from '../models/assignments-filter-level.model';
import { CustomValidators } from '../../shared/validators/custom.validator';

@Component({
    selector: 'app-assignments-filter-modal',
    templateUrl: './assignments-filter-modal.component.html',
    styleUrl: './assignments-filter-modal.component.scss'
})
export class AssignmentsFilterModalComponent implements OnInit {
    assignmentsFilterSubject?: BehaviorSubject<AssignmentsFilterModel>;
    assignmentsFilterModel?: AssignmentsFilterModel;
    filterForm!: FormGroup;

    filterOptions: AssignmentsFilterOptionsModel | null = null;

    submitted: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private bsModalService: BsModalService,
        private assignmentStateService: AssignmentStateService,
        private assignmentService: AssignmentsService
    ) { }

    ngOnInit(): void {
        this.loadFilterOptions();
        this.initForm();
        if (this.assignmentsFilterSubject) {
            this.loadSavedFilters();
        }
    }

    public saveForm(): void {
        this.submitted = true;
        this.filterForm.markAllAsTouched();

        if (!this.filterForm.valid) return;

        const formValue = this.filterForm.value;
        const weekdaysValue = formValue.weekdays;

        const assignmentsFilter: AssignmentsFilterModel = {
            dateFrom: formValue.dateFrom ? new Date(formValue.dateFrom).toLocaleDateString("de-CH") : null,
            dateTo: formValue.dateTo ? new Date(formValue.dateTo).toLocaleDateString("de-CH") : null,
            skillLevels: this.levelIdsToFilterLevelModels(formValue.skillLevels),
            weekdays: {
                isMondayActive: weekdaysValue.isMondayActive,
                isTuesdayActive: weekdaysValue.isTuesdayActive,
                isWednesdayActive: weekdaysValue.isWednesdayActive,
                isThursdayActive: weekdaysValue.isThursdayActive,
                isFridayActive: weekdaysValue.isFridayActive,
                isSaturdayActive: weekdaysValue.isSaturdayActive,
                isSundayActive: weekdaysValue.isSundayActive
            },
            searchText: formValue.searchText,
            isWeekdayFilterActive: formValue.isWeekdayFilterActive
        };

        if (this.assignmentsFilterSubject) {
            this.assignmentsFilterSubject.next(assignmentsFilter);
        }

        this.bsModalService.hide();
    }

    private levelIdsToFilterLevelModels(levelIds: number[] | null): AssignmentsFilterLevelModel[] {
        if (levelIds == null) return [];
        if (this.filterOptions?.levels == null) return [];

        var models = this.filterOptions.levels.filter(x => levelIds.find(l => l == x.id)).map(x => {
            return <AssignmentsFilterLevelModel>{
                id: x.id,
                name: x.name
            };
        });

        return models;
    }

    public clearForm(): void {
        this.filterForm.setValue(
            this.assignmentStateService.setAssignmentFilterDefault()
        )
    }

    private initForm(): void {
        this.filterForm = this.formBuilder.group({
            searchText: [''],
            skillLevels: [[]],
            dateFrom: [''],
            dateTo: [''],
            weekdays: this.formBuilder.group({
                isMondayActive: [true],
                isTuesdayActive: [true],
                isWednesdayActive: [true],
                isThursdayActive: [true],
                isFridayActive: [true],
                isSaturdayActive: [true],
                isSundayActive: [true]
            }),
            isWeekdayFilterActive: [false]
        });

        // add validator after form initialzing
        this.filterForm.controls["dateTo"].addValidators([CustomValidators.greaterOrEqualThan(this.filterForm.controls["dateFrom"])]);
    }

    private loadSavedFilters(): void {
        if (!this.assignmentsFilterSubject) return;

        this.assignmentsFilterSubject.subscribe(savedFilter => {
            if (savedFilter == undefined) return;

            const weekdays = savedFilter.weekdays!;

            this.filterForm.patchValue({
                searchText: savedFilter.searchText,
                skillLevels: savedFilter.skillLevels?.flat().map(({ id }) => id),
                dateFrom: savedFilter.dateFrom,
                dateTo: savedFilter.dateTo,
                weekdays: {
                    isMondayActive: weekdays.isMondayActive,
                    isTuesdayActive: weekdays.isTuesdayActive,
                    isWednesdayActive: weekdays.isWednesdayActive,
                    isThursdayActive: weekdays.isThursdayActive,
                    isFridayActive: weekdays.isFridayActive,
                    isSaturdayActive: weekdays.isSaturdayActive,
                    isSundayActive: weekdays.isSundayActive
                },
                isWeekdayFilterActive: savedFilter.isWeekdayFilterActive
            });
        });
    }

    private loadFilterOptions(): void {
        this.assignmentService.getAssignmentsFilterOptions().subscribe({
            next: (filterOptions) => {
                filterOptions.levels = filterOptions.levels.sort((a, b) => a.name.localeCompare(b.name));
                this.filterOptions = filterOptions;
            }
        })
    }

    protected validator(fieldNamed: string): boolean {
        const form_field = this.filterForm.get(fieldNamed);
        form_field?.validator

        console.log(form_field?.validator);

        return true;
    }

    public hasValidator(controlName: string, validatorName: string): boolean {
        if (!this.submitted) return false;
        return CustomValidators.hasValidator(this.filterForm, controlName, validatorName);
    }

}

