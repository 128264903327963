import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { ImpersonationStateService } from './impersonation-state.service';
import { ImpersonationPersonModel } from '../models/impersonation-person.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AvailableImpersonationPersonModel } from '../models/available-impersonation-person.model';

@Injectable({
    providedIn: 'root'
})

export class ImpersonationService {

    constructor(
        private router: Router,
        private httpService: HttpClient,
        private cookieService: CookieService,
        private impersonationStateService: ImpersonationStateService
    ) { }

    public getImpersonationUserId(): number | null {
        if (!this.cookieService.check(environment.impersonation.impersonationCookieUserIdKey)) return null;

        let personId = this.cookieService.get(environment.impersonation.impersonationCookieUserIdKey)
        return Number(personId);
    }

    public startImpersonation(impersonationUserId: number) {
        this.cookieService.set(environment.impersonation.impersonationCookieUserIdKey, impersonationUserId.toString(), 1, '/', environment.impersonation.impersonationCookieDomain);
        this.updateActiveImpersonation();
    }

    public endActiveImpersonation() {
        this.cookieService.delete(environment.impersonation.impersonationCookieUserIdKey, '/', environment.impersonation.impersonationCookieDomain);
        this.updateActiveImpersonation();
    }

    public updateActiveImpersonation(): void {

        var impersonationUserId = this.getImpersonationUserId()

        if (impersonationUserId == null) {
            this.impersonationStateService.activeImpersonationPerson.next(null);
            this.impersonationStateService.activeImpersonationUserId.next(null);
            return;
        }

        this.impersonationStateService.activeImpersonationUserId.next(impersonationUserId);

        this.getImpersonationPersonDetails(impersonationUserId!).subscribe({
            next: (x) => {
                this.impersonationStateService.activeImpersonationPerson.next(x);
            },
            error: () => {
                this.router.navigate(['impersonation', 'end']);
            }
        })
    }

    public getAvailableImpersonationPersons(): Observable<AvailableImpersonationPersonModel[]> {
        return this.httpService.get<AvailableImpersonationPersonModel[]>(`${environment.api}/Admin/Impersonation/AvailableTrainers`);
    }

    private getImpersonationPersonDetails(personId: number): Observable<ImpersonationPersonModel> {
        return this.httpService.get<ImpersonationPersonModel>(`${environment.api}/Admin/Impersonation/Persons/${personId}/Details`);
    }
}
