import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpResponseStatus } from '../../shared/http-status-code.enum';

@Injectable({
    providedIn: 'root'
})
export class HttpResponseErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                this.logError(error.status, location.href)
                switch (error.status) {

                    case HttpResponseStatus.CODE_404_NOT_FOUND:
                        this.router.navigateByUrl('/notFound');
                        return EMPTY;

                    case HttpResponseStatus.CODE_400_BAD_REQUEST:
                    case HttpResponseStatus.CODE_401_DENIED:
                    case HttpResponseStatus.CODE_403_FORBIDDEN:
                    case HttpResponseStatus.CODE_409_CONFLICT:
                        this.router.navigateByUrl('/error');
                        return EMPTY;

                    case HttpResponseStatus.CODE_404_NOT_FOUND:
                        this.router.navigateByUrl('/notFound');
                        return EMPTY;

                    case HttpResponseStatus.CODE_429_TOO_MANY_REQUESTS:
                    default:
                        return throwError(() => error);

                }
            }));
    }

    private logError(errorStatus: number, url: string): void {
        console.log(`INFO: HTTP-Response: ${errorStatus} on Page: ${url}`);
    }
}