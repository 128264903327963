<!-- Logged in -->
<ng-container *ngIf="isLoggedIn && isStaffOrAdmin">
    <div class="d-flex flex-column gap-3">
        <app-under-construction></app-under-construction>
        <app-news></app-news>
        <!-- Upcoming Assignments -->
        <app-page-divider [text]="'home.upcomingAssignments.title' | transloco"
            [icon]="'fa-solid fa-calendar-days'"></app-page-divider>
        <app-assignments-list [assignmentSubject]="assignmentsStateService.topThreeUpcomingAssignmentSubjects"
            [eventType]="assignmentEventTypes.RouteToDetails"></app-assignments-list>
        <div class="d-flex justify-content-center">
            <button [routerLink]="'/assignments/my'" class="btn btn-default">
                {{ "home.showMore.title" | transloco }}
            </button>
        </div>

        <!-- Open Assignments -->
        <app-page-divider [text]="'home.openAssignments.title' | transloco"
            [icon]="'fa-solid fa-calendar-days'"></app-page-divider>
        <app-assignments-list [assignmentSubject]="assignmentsStateService.topThreeOpenAssignmentSubjects"
            [eventType]="assignmentEventTypes.CustomAction"
            (clicked$)="openAssignmentClicked($event)"></app-assignments-list>
        <div class="d-flex justify-content-center">
            <button [routerLink]="'/assignments/open'" class="btn btn-default">
                {{ "home.showMore.title" | transloco }}
            </button>
        </div>
    </div>
</ng-container>

<!-- Not logged in -->
<ng-container *ngIf="isLoggedIn != undefined && !isLoggedIn">
    <div class="d-flex flex-column gap-5 p-3 mt-5">
        <div class="d-flex flex-column justify-content-center align-items-center bg-primary rounded p-5">
            <p class="fw-bold text-white text-center fs-1 m-0">{{ 'home.loginWelcome' | transloco }}</p>
        </div>
        <div class="d-flex justify-content-center">
            <div class="col-12 col-md-5">
                <button class="btn btn-primary w-100 fs-4" (click)="login()">
                    {{ 'home.login' | transloco }}
                </button>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isLoggedIn && !isStaffOrAdmin">
    <div class="alert alert-warning">
        {{'home.unauthorized' | transloco}}
    </div>
</ng-container>