import { Component, OnInit } from '@angular/core';
import { ImpersonationStateService } from '../../../security/services/impersonation-state.service';
import { ImpersonationService } from '../../../security/services/impersonation.service';
import { UserAuthorizationService } from '../../../security/user-authorization.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-impersonation-indicator',
    templateUrl: './impersonation-indicator.component.html',
    styleUrls: ['./impersonation-indicator.component.scss'],
})
export class ImpersonationIndicatorComponent implements OnInit {

    isLoggedIn: boolean = false;

    constructor(
        private router: Router,
        protected authService: UserAuthorizationService,
        private impersonationService: ImpersonationService,
        protected impersonationStateService: ImpersonationStateService
    ) { }

    ngOnInit() {
        this.impersonationService.updateActiveImpersonation();
        this.authService.isLoggedIn().then((x) => {
            this.isLoggedIn = x;
        });
    }

    public endImpersonation() {
        this.router.navigate(['impersonation', 'end']);
    }
}
