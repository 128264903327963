import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AssignmentAttendanceModel } from '../models/assignment-attendance.model';
import { AssignmentAttendancePostModel } from '../models/assignment-attendance-post.model';
import { AssignmentAttendanceResultModel } from '../models/assignment-attendance-result.model';

@Injectable({
  providedIn: 'root'
})
export class AssignmentsAttendanceService {

  constructor(private httpClient: HttpClient) { }

  getAssignmentAttendances(assignmentId: number): Observable<AssignmentAttendanceModel> {
    return this.httpClient.get<AssignmentAttendanceModel>(`${environment.api}/assignments/${assignmentId}/attendances`);
  }

  sendAssignmentAttendances(assignmentId: number, attendances: AssignmentAttendancePostModel): Observable<AssignmentAttendanceResultModel> {
    return this.httpClient.post<AssignmentAttendanceResultModel>(`${environment.api}/assignments/${assignmentId}/attendances/set`, attendances);
  }
}
