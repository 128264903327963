import { Component } from '@angular/core';

@Component({
  selector: 'app-know-how-documents',
  templateUrl: './know-how-documents.component.html',
  styleUrl: './know-how-documents.component.scss'
})
export class KnowHowDocumentsComponent {

}
