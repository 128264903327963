import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AssignmentCommunicationUpdatePostModel } from '../../../models/assignment-communication-update-post.model';
import { AssignmentsCommunicationStateService } from '../../../services/assignments-communication-state-service';
import { AssignmentsCommunicationService } from '../../../services/assignments-communication.service';
import { AssignmentChangeCommunicationResultModel } from '../../../models/assignment-change-communication-result.model';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-assignment-communication-update-modal',
    templateUrl: './assignment-communication-update-modal.component.html',
    styleUrl: './assignment-communication-update-modal.component.scss'
})
export class AssignmentCommunicationUpdateModalComponent {
    assignmentCommunicationUpdateModel?: AssignmentCommunicationUpdatePostModel;
    updateResponse: AssignmentChangeCommunicationResultModel | null = null;
    updateForm?: FormGroup;
    assignmentId!: number;
    isLoading: boolean = true;

    constructor(
        private assignmentCommunicationStateService: AssignmentsCommunicationStateService,
        private assignmentCommunicationService: AssignmentsCommunicationService,
        private formBuilder: FormBuilder,
        private bsModalRef: BsModalRef
    ) { }

    ngOnInit(): void {
        this.assignmentCommunicationService.getAssignmentCommunication(this.assignmentId)
            .subscribe({
                next: (communicationModel) => {
                    this.isLoading = false
                    this.initForm(communicationModel.update);
                }
            });
    }

    public saveForm(): void {
        if (this.updateForm?.valid) {
            this.sendAssignmentCommunicationUpdate();
        }
    }

    private sendAssignmentCommunicationUpdate(): void {
        this.isLoading = true;

        this.assignmentCommunicationUpdateModel = { update: this.updateForm?.value.update };
        this.assignmentCommunicationService.sendAssignmentCommunicationUpdate(this.assignmentId, this.assignmentCommunicationUpdateModel)
            .subscribe({
                next: (response) => {
                    this.isLoading = false;
                    if (response.isSuccess) {
                        this.assignmentCommunicationStateService.loadAssignmentCommunication(this.assignmentId);
                        this.bsModalRef.hide();
                    }
                    this.updateResponse = response;
                },
                error: (error) => {
                    this.isLoading = false;
                }
            });
    }

    private initForm(value: string): void {
        this.updateForm = this.formBuilder.group({
            update: [value]
        });
    }
}
