import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentsOverviewModel } from '../models/assignments-overview.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { AssignmentSubstituteModel } from '../models/assignments-substitute.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssignmentsService } from '../services/assignments.service';
import { SportService } from '../../sports/services/sports.service';
import { SportTrainerModel } from '../../sports/models/sport-trainer.model';
import { AssignmentExchangeModel } from '../models/assignment-exchange.model';

@Component({
    selector: 'app-exchange-assignments-modal',
    templateUrl: './exchange-assignments-modal.component.html',
    styleUrl: './exchange-assignments-modal.component.scss'
})
export class ExchangeAssignmentsModalComponent implements OnInit {
    @Output() onSuccess = new EventEmitter<boolean>();

    @Input() exchangeAssignments: AssignmentExchangeModel[] = [];
    @Input() isVacacyAllowed: boolean = false;

    isMoreThenOneSportSelected: boolean = false;

    isCloseOnly: boolean = false;
    trainers$!: Observable<SportTrainerModel[] | null>;
    exchangeAssignmentsForm!: FormGroup;
    selectedTrainerName: string = '';

    private trainerSubject = new BehaviorSubject<SportTrainerModel[] | null>(null)

    constructor(
        private sportService: SportService,
        private formBuilder: FormBuilder,
        private assignmentService: AssignmentsService,
    ) { }

    ngOnInit(): void {
        this.initForm();
        this.loadInitialDate();
        this.onTrainerSelectionChange();
        this.checkIfMoreThenOneSportSelected();
    }

    public sendSubsituteAssignments(substituteAssignments: AssignmentSubstituteModel): void {
        this.assignmentService.sendSubstituteAssignments(substituteAssignments).subscribe(
            {
                next: (response) => {
                    response.forEach((resultModel) => {
                        const matchingAssignment = this.exchangeAssignments.
                            find((assignment) => assignment.assignment.id == resultModel.assignmentId);

                        if (matchingAssignment) {
                            matchingAssignment.isSuccess = resultModel.isSuccessful;
                            matchingAssignment.errorMessage = resultModel.errorMessage;
                        }
                    });
                    this.onSuccess.emit(true);
                },
                error: (error) => {
                    this.exchangeAssignmentsForm.controls['selectedTrainer']?.enable();
                }
            }
        )
        this.isCloseOnly = true;
    }

    protected setSubstitute(): void {
        this.exchangeAssignmentsForm.markAllAsTouched();
        if (!this.exchangeAssignmentsForm?.valid) return;

        this.exchangeAssignmentsForm.controls['selectedTrainer']?.disable();

        let subsituteAssignments = this.createAssignmentsSubstituteModel();

        this.sendSubsituteAssignments(subsituteAssignments);
    }

    protected isAssignmentOnSameDay(from: Date | undefined, to: Date | undefined): boolean {
        if (!from || !to) return false;
        return new Date(from).toDateString() === new Date(to).toDateString();
    }

    private initForm(): void {
        this.exchangeAssignmentsForm = this.formBuilder.group({
            selectedTrainer: [null, Validators.required]
        })
        this.exchangeAssignmentsForm.markAllAsTouched();
    }

    private loadInitialDate(): void {
        if (this.exchangeAssignments.length > 0 && this.exchangeAssignments[0].assignment.sportId) {

            this.exchangeAssignments.sort((a, b) => {
                const dateA = a.assignment.from ? new Date(a.assignment.from).getTime() : 0;
                const dateB = b.assignment.to ? new Date(b.assignment.to).getTime() : 0;
                return dateA - dateB;
            });

            var sportIds: number[] = this.exchangeAssignments.flat().map(x => x.assignment.sportId!);
            this.loadTrainers(sportIds);
        }
        console.log("Selected Assignments Modal: ", this.exchangeAssignments);
        this.trainers$ = this.trainerSubject;
    }

    private loadTrainers(sportIds: number[]) {
        this.sportService.getTrainers(sportIds).subscribe({
            next: trainers => {
                trainers = trainers.sort((a, b) => (a.firstName + b.lastName).localeCompare((b.firstName + b.lastName)));
                this.trainerSubject.next(trainers);
            }
        })
    }

    private createAssignmentsSubstituteModel(): AssignmentSubstituteModel {
        var selectedTrainer: number | null = this.exchangeAssignmentsForm.value.selectedTrainer;
        if (selectedTrainer == 0) selectedTrainer = null;

        return {
            userId: selectedTrainer,
            assignmentIds: this.exchangeAssignments
                .map(assignment => assignment.assignment.id)
                .filter((id) => id !== undefined)
        };
    }

    private onTrainerSelectionChange(): void {
        this.exchangeAssignmentsForm.get('selectedTrainer')?.valueChanges.subscribe((trainerId: number) => {
            const selectedTrainer = this.trainerSubject.getValue()?.find(trainer => trainer.id === trainerId);

            this.selectedTrainerName = selectedTrainer ? `${selectedTrainer.firstName} ${selectedTrainer.lastName}` : '';
        });
    }

    private checkIfMoreThenOneSportSelected(): void {
        if (this.exchangeAssignments == null) return;
        if (this.exchangeAssignments.length <= 1) this.isMoreThenOneSportSelected = false;

        let sports = this.exchangeAssignments.flat().filter(x => x.assignment?.sportId != undefined).map(x => x.assignment.sportId!);
        sports = sports.filter((value, index, array) => array.indexOf(value) === index);
        this.isMoreThenOneSportSelected = (sports.length > 1);
    }
}
