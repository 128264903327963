import { UserAuthorizationService } from './user-authorization.service';
import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthGuard } from './guards';
import { LogoutComponent } from './logout/logout.component';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { ImpersonationInterceptor } from './interceptors/impersontation.interceptor';
import { HttpResponseErrorInterceptor } from './interceptors/http-response-error.interceptor';
import { AdminAuthGuard } from './guards/admin-auth-guard.service';
import { StaffAuthGuard } from './guards/staff-auth-guard.service';
@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        UserAuthorizationService,
        AuthGuard,
        AdminAuthGuard,
        StaffAuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpResponseErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ImpersonationInterceptor, multi: true },
    ],
    declarations: [
        LogoutComponent,
        AuthCallbackComponent,
    ],
})
export class SecurityModule {

    constructor(@Optional() @SkipSelf() parentModule: SecurityModule) {
        if (parentModule) {
            throw new Error(
                'SecurityModule is already loaded. Import it in the AppModule only');
        }
    }

}
