import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AssignmentsOverviewModel } from '../models/assignments-overview.model';
import { AssignmentChangeSubstituteResultModel } from '../models/assignment-change-substitute-result.model';
import { AssignmentsService } from '../services/assignments.service';
import { AssignmentTakeoverPostModel } from '../models/assignments-takeover-post.model';
import { AssignmentTakeoverModel } from '../models/assignment-takeover.model';

@Component({
    selector: 'app-open-assignments-modal',
    templateUrl: './open-assignments-modal.component.html',
    styleUrl: './open-assignments-modal.component.scss'
})
export class OpenAssignmentsModalComponent implements OnInit {
    @Output() onSuccess = new EventEmitter<boolean>();
    isCloseOnly: boolean = false;
    takeoverAssignments: AssignmentTakeoverModel[] = [];

    constructor(
        private assignmentService: AssignmentsService
    ) { }

    ngOnInit(): void {
        this.sortSelectedAssignments();
    }

    public sendTakeoverAssignments(takeoverAssignments: AssignmentTakeoverPostModel): void {
        this.assignmentService.sendTakeoverAssignments(takeoverAssignments).subscribe(
            (response: AssignmentChangeSubstituteResultModel[]) => {
                response.forEach((resultModel) => {
                    const matchingAssignment = this.takeoverAssignments.
                        find((assignmentTakeoverModel) => assignmentTakeoverModel.assignment.id == resultModel.assignmentId);
                    if (matchingAssignment) {
                        matchingAssignment.isSuccess = resultModel.isSuccessful;
                        matchingAssignment.errorMessage = resultModel.errorMessage;
                    }
                });
                this.onSuccess.emit(true);
            }
        )
        this.isCloseOnly = true;
    }

    protected setTakeoverModel(): void {
        const postModel = this.createAssignmentsTakeoverModel();
        this.sendTakeoverAssignments(postModel);
    }

    protected isAssignmentOnSameDay(from: Date | undefined, to: Date | undefined): boolean {
        if (!from || !to) return false;
        return new Date(from).toDateString() === new Date(to).toDateString();
    }

    private createAssignmentsTakeoverModel(): AssignmentTakeoverPostModel {
        return {
            assignmentIds: this.takeoverAssignments
                .map(assignment => assignment.assignment.id)
                .filter(id => id !== undefined)
        };
    }

    private sortSelectedAssignments(): void {
        this.takeoverAssignments.sort((a, b) => {
            const dateA = a.assignment.from ? new Date(a.assignment.from).getTime() : 0;
            const dateB = b.assignment.to ? new Date(b.assignment.to).getTime() : 0;
            return dateA - dateB;
        });
    }
}
