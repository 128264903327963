import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { AssignmentEventType } from '../../shared/enums/AssignmentEventType.enums';
import { AssignmentStateService } from '../services/assignment-state.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentsFilterModalComponent } from '../assignments-filter-modal/assignments-filter-modal.component';
import { AssignmentsFilterStateService } from '../services/assignments-filter-state.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
    templateUrl: './billed-assignments.component.html',
    styleUrl: './billed-assignments.component.scss',
    providers: [AssignmentsFilterStateService]
})
export class BilledAssignmentsComponent implements OnInit {
    assignmentEventType = AssignmentEventType;
    modalRef?: BsModalRef;

    constructor(
        public assignmentsStateService: AssignmentStateService,
        private assignmentsFilterStateService: AssignmentsFilterStateService,
        private headerStateService: HeaderStateService,
        private bsModalService: BsModalService,
        private translocoService: TranslocoService
    ) {
        this.headerStateService.setDetailHeader(
            this.translocoService.translate('main.header.billedAssignmentsTitle'),
            this.translocoService.translate('main.header.billedAssignmentsDescription'),
            '/assignments',
            'fa-solid fa-filter text-primary'
        );
    }

    ngOnInit(): void {
        let currentFilter = this.assignmentsStateService.billedAssignmentsFilterSubject.value;
        this.assignmentsStateService.loadBilledAssignments(currentFilter);
        this.registerEvents();
    }

    // Opening the FilterModal
    public openFilterModal(): void {
        this.modalRef = this.bsModalService.show(AssignmentsFilterModalComponent);
        this.modalRef.content.assignmentsFilterSubject = this.assignmentsStateService.billedAssignmentsFilterSubject;
        this.modalRef.content.loadSavedFilters();
    }

    // call only once in ngOnInit
    private registerEvents(): void {
        // Opening FilterModal in the Header
        this.headerStateService.getActionClickedEvent().subscribe((x) => {
            this.openFilterModal();
        })

        this.assignmentsFilterStateService.getIsAnyFilterDisplayedEvent().subscribe(x => {
            this.headerStateService.updateIsActiveStatusIndicatorDisplayed(x);
        });
    }
}
