import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserAuthorizationService } from '../user-authorization.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(protected authService: UserAuthorizationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.ensureUserIsLoggedIn(state);
    }

    async ensureUserIsLoggedIn(state: RouterStateSnapshot): Promise<boolean> {
        var isLoggedIn = await this.authService.isLoggedIn();
        if (isLoggedIn) return true;
        // redirect to auth
        this.authService.startAuthentication(state.url);
        return false;
    }
}