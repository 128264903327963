<div class="my-3">
    <div class="d-flex flex-column gap-2">
        <!-- Loading -->
        <ng-container *ngIf="sportTrainers == null">
            <div class="text-center">
                <span>
                    <i class="fa-solid fa-spinner fa-spin"></i>
                </span>
            </div>
        </ng-container>

        <!-- Loaded -->
        <ng-container *ngIf="sportTrainers != null">
            <ng-container *ngFor="let sportTrainer of sportTrainers ; let  sportIndex = index">
                <app-collapsible-card [cardTitle]="sportTrainer.sportName">
                    <div class="card-body p-0">
                        <ul class="list-group list-group-flush mb-3">
                            <li class="list-group-item bg-light">
                                <p class="mb-0"><b>{{ 'know-how.adminCardTitle' | transloco }}</b></p>
                            </li>
                            <li *ngFor="let sportTrainerAdmin of sportTrainer.trainerAdmins" class="list-group-item">
                                <label>{{ sportTrainerAdmin.firstName }} {{ sportTrainerAdmin.lastName }}</label>
                            </li>
                            <li class="list-group-item bg-light">
                                <p class="mb-0"><b>{{ 'know-how.trainerCardTitle' | transloco }}</b></p>
                            </li>
                            <li *ngFor="let trainer of sportTrainer.trainerContacts;  let  trainersIndex = index"
                                class="list-group-item">
                                <label for="trainer-{{sportIndex}}-{{trainersIndex}}"
                                    class="d-flex align-middle justify-content-between">
                                    <input id="trainer-{{sportIndex}}-{{trainersIndex}}"
                                        [(ngModel)]="trainer.isSelected" type="checkbox" class="me-2" />
                                    <span class="flex-grow-1">
                                        {{ trainer.firstName }} {{ trainer.lastName }}
                                    </span>
                                    <span>{{ trainer.mobilePhone }}</span>
                                </label>
                            </li>
                        </ul>
                        <div class="card-footer d-flex flex-md-row flex-column gap-2 p-2 justify-content-end">
                            <button (click)="sendMessageToSelected(sportTrainer)" type="submit"
                                class="btn btn-default btn-sm" [disabled]="!hasSelectedTrainers(sportTrainer)">
                                {{ 'know-how.mailToSelected' | transloco }}
                            </button>
                            <button (click)="sendMessageToAll(sportTrainer)" class="btn btn-default btn-sm">
                                {{ 'know-how.mailToAll' | transloco }}
                            </button>
                        </div>
                    </div>
                </app-collapsible-card>
            </ng-container>
        </ng-container>
    </div>
</div>