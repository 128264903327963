import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpStatusCode, } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ImpersonationService } from '../services/impersonation.service';

@Injectable()
export class ImpersonationInterceptor implements HttpInterceptor {
    constructor(
        private impersonationService: ImpersonationService,
        private router: Router
    ) { }

    private checkUrl(url: string): boolean {
        if (!environment.impersonation.allowedDomains) return true;
        return !!environment.impersonation.allowedDomains.find((u) => url.toLowerCase().startsWith(u.toLowerCase()));
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const url = req.url.toLowerCase();

        if (!this.checkUrl(url)) return next.handle(req);
        const impersonationUserId = this.impersonationService.getImpersonationUserId();

        if (impersonationUserId == null) return next.handle(req);

        const headers = req.headers.set('impersonation-id', impersonationUserId.toString());
        const requestClone = req.clone({ headers });

        return next.handle(requestClone).pipe(
            catchError((error: HttpErrorResponse) => {
                this.navigateIfExceptionIsImpersonationException(error);
                return throwError(() => error);
            })
        );
    }

    private navigateIfExceptionIsImpersonationException(error: HttpErrorResponse): void {
        if (error.status != HttpStatusCode.Forbidden) return;
        if (!('ImpersonationException' in error.error)) return;

        this.router.navigate(['impersonation', 'end']);
    }

}

