<div class="d-flex flex-column gap-3">
    <app-page-divider [text]="'assignments.information.title' | transloco" [icon]="'fa-solid fa-circle-info'">
    </app-page-divider>
    <app-assignment-information></app-assignment-information>

    <app-page-divider [text]="'assignments.instructors.title' | transloco" [icon]="'fa-solid fa-user-group'">
    </app-page-divider>
    <app-assignment-instructors [assignmentId]="assignmentId"></app-assignment-instructors>

    <ng-container *ngIf="(assignmentDates?.length ?? 0) > 1">
        <app-page-divider [text]="'assignments.courseDates.title' | transloco" [icon]="'fa-solid fa-map-pin'">
        </app-page-divider>
        <app-assignment-dates [assignmentDates]="assignmentDates!">
        </app-assignment-dates>
    </ng-container>
    <app-page-divider [text]="'assignments.participants.title' | transloco" [icon]="'fa-solid fa-users'">
    </app-page-divider>
    <app-assignment-participant [assignmentId]="assignmentId"></app-assignment-participant>
    
    <app-page-divider [text]="'assignments.communication.title' | transloco" [icon]="'fa-solid fa-bullhorn'">
    </app-page-divider>
    <app-assignment-communication [assignmentId]="assignmentId"></app-assignment-communication>
    <app-page-divider [text]="'assignments.attendance.title' | transloco" [icon]="'fa-solid fa-user-check'">

    </app-page-divider>
    <app-assignment-attendance [assignmentId]="assignmentId"></app-assignment-attendance>
    <app-page-divider [text]="'assignments.skills.title' | transloco" [icon]="'fa-solid fa-rocket'">

    </app-page-divider>
    <app-assignment-sportskills [assignmentId]="assignmentId"></app-assignment-sportskills>
</div>