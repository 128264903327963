<pmt-action-modal [title]="'know-how.modalTitle' | transloco" submitButtonIconCode=""
    [submitButtonText]="'modal.sendButton' | transloco" (submitted)="onSubmit()" class="custom-modal-limiter">
    <ng-container *ngIf="trainers">
        <div class="d-flex flex-column gap-2" [formGroup]="messageForm">
            <div>
                <label>Von</label>
                <div class="m-0">
                    <span class="badge bg-secondary" [tooltip]="sender?.email">
                        {{ sender?.given_name }} {{ sender?.family_name }}
                    </span>
                </div>
            </div>

            <div>
                <label>An</label>
                <div class="m-0 d-flex flex-wrap gap-1">
                    <span *ngFor="let trainer of trainers" class="badge bg-secondary" [tooltip]="trainer.email">
                        {{ trainer.firstName }} {{ trainer.lastName }}
                    </span>
                </div>
            </div>

            <div>
                <label>Cc</label>
                <div class="m-0 d-flex flex-wrap gap-1">
                    <span *ngFor="let trainerAdmin of sportTrainers.trainerAdmins" class="badge bg-secondary"
                        [tooltip]="trainerAdmin.email">
                        {{ trainerAdmin.firstName }} {{ trainerAdmin.lastName }}
                    </span>
                </div>
            </div>

            <div class="text-align-center">
                <input class="me-2" id="sendCopy" type="checkbox" formControlName="isSendCopy" />
                <label for="sendCopy">{{ "know-how.sendCopy" | transloco }}</label>
            </div>

            <div>
                <input type="text" formControlName="subject" class="form-control" [disabled]="isLoading" />
            </div>

            <div>
                <label for="email">Text</label>
                <textarea id="email" formControlName="trainerMessage" rows="5" class="form-control"
                    [disabled]="isLoading" [placeholder]="'know-how.mailPlaceHolder' | transloco"></textarea>
            </div>
        </div>
    </ng-container>
</pmt-action-modal>