import { Component } from '@angular/core';

@Component({
  selector: 'app-know-how-links',
  templateUrl: './know-how-links.component.html',
  styleUrl: './know-how-links.component.scss'
})
export class KnowHowLinksComponent {

}
