import { Component } from '@angular/core';
import { HeaderStateService } from '../main/headers/services/header-state.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-know-how',
  templateUrl: './know-how.component.html',
  styleUrl: './know-how.component.scss'
})
export class KnowHowComponent {
  constructor(
    private headerStateService: HeaderStateService,
    private translocoService: TranslocoService
  ) {
    this.headerStateService.setDetailHeader(
      this.translocoService.translate('main.header.knowHowTitle'),
      this.translocoService.translate('main.header.knowHowDescription'))
  }
}

