<div class="impersonation-indicator" *ngIf="isLoggedIn && (impersonationStateService.isImpersonationActive | async)">
    <div class="container d-flex justify-content-center p-1">
        <div *ngIf="
        impersonationStateService.activeImpersonationPerson | async as person">
            <button class="btn btn-sm btn-warning m-0" (click)="endImpersonation()">
                {{ "impersonation.from" | transloco }}
                <b>{{ person.firstName }} {{ person.lastName }} ({{ person.asvzId }})</b>
                {{ "impersonation.end" | transloco }}
            </button>
        </div>
    </div>
</div>