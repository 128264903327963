import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AttendanceStatus } from '../../enums/attendance-status.enum';
import { AssignmentAttendanceStateService } from '../../services/assignment-attendance-state.service';
import { AssignmentAttendanceDateModel } from '../../models/assignment-attendance-date.model';
import { AssignmentAttendancePostModel } from '../../models/assignment-attendance-post.model';
import { AssignmentsAttendanceService } from '../../services/assignments-attendance.service';

@Component({
  selector: 'app-assignment-attendance-modal',
  templateUrl: './assignment-attendance-modal.component.html',
  styleUrls: ['./assignment-attendance-modal.component.scss'],
})
export class AssignmentAttendanceModalComponent implements OnInit {
  assignmentAttendances!: AssignmentAttendanceDateModel[];
  assignmentId!: number;
  attendanceForm!: FormGroup;

  constructor(
    protected assignmentAttendanceStateService: AssignmentAttendanceStateService,
    private assignmentAttendanceService: AssignmentsAttendanceService,
    private formBuilder: FormBuilder,
    private bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.loadAssingmentAttendances();
    this.initForm();
    this.loadAssignmentAttendancesFromSubject();
  }

  get participants(): FormArray {
    return this.attendanceForm.get('participants') as FormArray;
  }

  protected toggleAttendance(eventLessonId: number, personId: number): void {
    // Find the date object
    const date = this.assignmentAttendances.find((d) => d.eventLessonId === eventLessonId);
    if (!date) {
      console.error(`Date not found for eventLessonId: ${eventLessonId}`);
      return;
    }
  
    // Find the participant within the date
    const participant = date.participants.find((p) => p.personId === personId);
    if (!participant) {
      console.error(`Participant not found for personId: ${personId}`);
      return;
    }
  
    // Cycle the attendance status
    const nextStatus: { [key in AttendanceStatus]: AttendanceStatus } = {
      [AttendanceStatus.Undefined]: AttendanceStatus.Attended,
      [AttendanceStatus.Attended]: AttendanceStatus.Absent,
      [AttendanceStatus.Absent]: AttendanceStatus.Undefined,
    };
  
    participant.status = nextStatus[participant.status];
  
    console.log(`Updated participant status:`, participant);
  
    // Update the form control
    const participantControl = this.participants.controls.find(
      (control) => control.get('personId')?.value === personId
    );
  
    if (participantControl) {
      const attendanceControl = (participantControl.get('attendance') as FormArray).controls.find(
        (control) => control.value.eventLessonId === eventLessonId
      );
      if (attendanceControl) {
        attendanceControl.patchValue({ status: participant.status });
        console.log(`Updated form control for participant ${personId}, eventLessonId ${eventLessonId}`);
      } else {
        console.error(`Attendance control not found for eventLessonId: ${eventLessonId}`);
      }
    } else {
      console.error(`Participant form control not found for personId: ${personId}`);
    }
  }
  

  protected getButtonLabel(status: AttendanceStatus | null): string {
    const labels = {
      [AttendanceStatus.Attended]: '✔',
      [AttendanceStatus.Absent]: '✖',
      [AttendanceStatus.Undefined]: '-',
    };
    return labels[status ?? AttendanceStatus.Undefined];
  }

  protected getButtonClass(status: AttendanceStatus | null): string {
    const classes = {
      [AttendanceStatus.Attended]: 'btn-green',
      [AttendanceStatus.Absent]: 'btn-danger',
      [AttendanceStatus.Undefined]: 'btn-primary',
    };
    return classes[status ?? AttendanceStatus.Undefined];
  }

  protected formatName(firstName: string, lastName: string): string {
    const [primaryName, ...otherNames] = firstName.split(' ');
    const secondInitial = otherNames[0]?.charAt(0) ?? '';
    return `${primaryName} ${secondInitial ? `${secondInitial}.` : ''} ${lastName.charAt(0)}.`;
  }

  protected sendForm(): void {
    const postData: AssignmentAttendancePostModel = {
      attendanceDates: this.assignmentAttendances.map((date) => ({
        eventLessonId: date.eventLessonId,
        attendances: this.participants.controls.map((participantGroup) => {
          const attendance = (participantGroup.get('attendance') as FormArray).controls.find(
            (control) => control.value.eventLessonId === date.eventLessonId
          );
          return {
            personId: participantGroup.get('personId')?.value,
            attendanceStatus: attendance?.get('status')?.value || AttendanceStatus.Undefined,
          };
        }),
      })),
    };

    this.assignmentAttendanceService.sendAssignmentAttendances(this.assignmentId, postData).subscribe({
      next: () => this.bsModalRef.hide(),
      error: (err) => console.error(err),
    });
  }

  private initForm(): void {
    this.attendanceForm = this.formBuilder.group({
      participants: this.formBuilder.array([]),
    });
  }

  private loadAssingmentAttendances(): void {
    this.assignmentAttendanceStateService.loadAssingmentAttendances(this.assignmentId);
  }

  private loadAssignmentAttendancesFromSubject(): void {
    this.assignmentAttendanceStateService.assignmentAttendanceSubject.subscribe({
      next: (attendanceData) => {
        if (attendanceData) {
          this.assignmentAttendances = attendanceData.attendanceDates;
          this.populateParticipants();
        }
      },
    });
  }

  private populateParticipants(): void {
    if (!this.assignmentAttendances) return;

    this.participants.clear();

    const firstLessonParticipants = this.assignmentAttendances[0]?.participants || [];
    firstLessonParticipants.forEach((participant) =>
      this.participants.push(this.createParticipantFormGroup(participant))
    );
  }

  private createParticipantFormGroup(participant: any): FormGroup {
    return this.formBuilder.group({
      personId: [participant.personId],
      attendance: this.formBuilder.array(
        this.assignmentAttendances.map((date) =>
          this.createAttendanceFormGroup(date.eventLessonId, participant.status)
        )
      ),
    });
  }

  private createAttendanceFormGroup(eventLessonId: number, status: AttendanceStatus): FormGroup {
    return this.formBuilder.group({
      eventLessonId: [eventLessonId],
      status: [status || AttendanceStatus.Absent],
    });
  }
}
