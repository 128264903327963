import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NewsModel } from "../../news/models/news.model";
import { Observable } from "rxjs";
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NewsService {

    constructor(private httpClient: HttpClient) { }

    public getNews(): Observable<NewsModel[]> {
        return this.httpClient.get<NewsModel[]>(`${environment.api}/news?take=3`);
    }

    public getNewsDetails(id: string): Observable<NewsModel> {
        return this.httpClient.get<NewsModel>(`${environment.api}/news/${id}`);
    }
}
