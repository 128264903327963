import { Component, Input } from '@angular/core';
import { AssignmentDetailsStateService } from '../../services/assignment-details-state.service';
import { DateHelper } from '../../../../shared/helpers/date.helper';
import { AssignmentDetailsDateModel } from '../../models/assignment-details-date.model';

@Component({
    selector: 'app-assignment-dates',
    templateUrl: './assignment-dates.component.html',
    styleUrl: './assignment-dates.component.scss'
})
export class AssignmentDatesComponent {
    @Input({ required: true }) assignmentDates!: AssignmentDetailsDateModel[];

    constructor(
        protected dateHelper: DateHelper,
        protected assignmentDetailsStateService: AssignmentDetailsStateService) { }

    protected IsOnSameDay(assignmentDetailsModel: AssignmentDetailsDateModel | null): boolean {
        if (assignmentDetailsModel == null) return false;
        return this.dateHelper.IsOnSameDay(assignmentDetailsModel.from, assignmentDetailsModel.to);
    }
}
