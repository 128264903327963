<!-- Loading -->
<ng-container *ngIf="(assignmentDetailsStateService.assignmentDetails | async) == null">
    <div class="text-center">
        <span><i class="fa-solid fa-spinner fa-spin"></i></span>
    </div>
</ng-container>

<ng-container *ngIf="(assignmentDetailsStateService.assignmentDetails | async) as details">
    <div class="d-flex flex-column gap-1 p-1">
        <!-- Detail Information -->
        <div class="row">
            <div class="col-12 col-sm-6">
                <label class="fw-bold">{{'assignments.information.eventNumber' | transloco}}</label>
                <p>{{ details.number }}</p>
            </div>
            <div class="col-12 col-sm-6">
                <label>{{'assignments.information.sportType' | transloco}}</label>
                <p>{{ details.sport }}</p>
            </div>
            <div class="col-12 col-sm-6">
                <label>{{'assignments.information.courseTitle' | transloco}}</label>
                <p>{{ details.title }}</p>
            </div>
            <div class="col-12 col-sm-6" *ngIf="details.level">
                <label>{{'assignments.information.level' | transloco}}</label>
                <p>{{ details.level }}</p>
            </div>
            <div class="col-12 col-sm-6" *ngIf="details.level">
                <label>{{'assignments.information.provSalary' | transloco}}</label>
                <p *ngIf="details.provSalaryStatus != null">{{ details.provSalaryStatus}}</p>
                <p *ngIf="details.provSalaryStatus == null && details.provSalary != null">
                    {{'swissFranc' |transloco}} {{ details.provSalary | number: '1.2-2'}}</p>
                <p *ngIf="details.provSalaryStatus == null && details.provSalary == null">-</p>
            </div>
        </div>

        <!-- Date and Location -->
        <div class="row">
            <div class="col-12 col-sm-6">
                <!-- Column Headers -->
                <label>{{'assignments.courseDates.date' | transloco}}</label>
                <!-- Different Start and End Dates -->
                <ng-container *ngIf="IsOnSameDay(details)">
                    <p>{{ details.from | date: "dd.MM.YY | HH:mm" }} - {{ details.to | date: "HH:mm" }}</p>
                </ng-container>

                <ng-container *ngIf="!IsOnSameDay(details)">
                    <p>{{ details.from | date: "dd.MM.YY | HH:mm" }} - {{ details.to | date: "dd.MM.YY | HH:mm" }}</p>
                </ng-container>
            </div>

            <div class="col-12 col-sm-6">
                <label>{{'assignments.courseDates.location' | transloco}}</label>
                <p *ngIf="!details.locations.length">-</p>
                <p *ngFor="let location of details.locations">
                    {{ location.facility }} | {{ location.room }}
                </p>
            </div>
        </div>

        <div class="">
            <button (click)="openCancellationModal()" [disabled]="!details.isCancelAllowed"
                class="btn btn-default w-100">
                {{'assignments.information.cancel' | transloco}}
            </button>
        </div>
    </div>
</ng-container>