import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AssignmentAttendanceDateModel } from "../models/assignment-attendance-date.model";
import { AssignmentsAttendanceService } from "./assignments-attendance.service";
import { AssignmentAttendanceModel } from "../models/assignment-attendance.model";

@Injectable({
    providedIn: 'root'
})

export class AssignmentAttendanceStateService {
    public assignmentAttendanceSubject = new BehaviorSubject<AssignmentAttendanceModel | null>(null);

    constructor(private assignmentAttendanceService: AssignmentsAttendanceService) { }

    public loadAssingmentAttendances(assignmentId: number): void {
        this.assignmentAttendanceService.getAssignmentAttendances(assignmentId).subscribe({
            next: assignmentAttendance => {
                this.assignmentAttendanceSubject.next(assignmentAttendance);
            },
            error: (error) => {
            }
        })
    }
}
