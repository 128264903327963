<pmt-action-modal [title]="'assignments.attendance.title'| transloco" submitButtonText="modal.saveButton"
    cancelButtonText="modal.cancelButton" submitButtonIconCode="" (submitted)="sendForm()" class="custom-modal-limiter">
    <!-- Loading State -->
    <ng-container *ngIf="(assignmentAttendanceStateService.assignmentAttendanceSubject | async) === null">
        <div class="text-center">
            <i class="fa-solid fa-spinner fa-spin"></i>
        </div>
    </ng-container>

    <!-- Main Content -->
    <ng-container *ngIf="assignmentAttendanceStateService.assignmentAttendanceSubject | async as assignmentAttendance">
        <form [formGroup]="attendanceForm">
            <div class="d-flex">
                <!-- Participants List -->
                <div class="participant-list border-end">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th class="text-start">{{ 'assignments.participants.title' | transloco }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let participant of assignmentAttendance.attendanceDates[0]?.participants">
                                <td class="p-2 border-bottom">
                                    <strong>{{ formatName(participant.firstName, participant.lastName) }}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Attendance Buttons -->
                <div class="lesson-list-container overflow-auto flex-grow-1">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th *ngFor="let date of assignmentAttendance.attendanceDates" class="text-center">
                                    <div class="d-flex flex-column align-items-center">
                                        <strong class="vertical">{{ date.from | date: 'dd.MM' }}</strong>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let participant of assignmentAttendance.attendanceDates[0]?.participants; let participantIndex = index">
                                <td *ngFor="let date of assignmentAttendance.attendanceDates; let dateIndex = index"
                                    class="text-center">
                                    <button type="button" class="btn btn-sm" [ngClass]="getButtonClass(
                      attendanceForm
                        .get(['participants', participantIndex, 'attendance', dateIndex, 'status'])
                        ?.value
                    )" (click)="toggleAttendance(date.eventLessonId, participant.personId)">
                                        {{
                                        getButtonLabel(
                                        attendanceForm
                                        .get(['participants', participantIndex, 'attendance', dateIndex, 'status'])
                                        ?.value
                                        )
                                        }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
    </ng-container>
</pmt-action-modal>