import { Injectable } from "@angular/core";
import { BehaviorSubject, map } from "rxjs";
import { AssignmentDetailsModel } from "../models/assignment-details.model";
import { AssignmentDetailsService } from "./assignment-details.service";

@Injectable({
    providedIn: 'root'
})

export class AssignmentDetailsStateService {
    public assignmentDetails = new BehaviorSubject<AssignmentDetailsModel | null>(null);

    constructor(private assignmentDetailsService: AssignmentDetailsService) { }

    public loadAssingmentDetails(assignmentId: number): void {
        this.assignmentDetailsService.getAssignmentDetails(assignmentId).pipe(map(assignment => {
            return assignment;
        }
        )).subscribe({
            next: assignmentDetails => {
                this.assignmentDetails.next(assignmentDetails);
            },
            error: (error) => {
            }
        })
    }
}
