import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AssignmentParticipantModel } from '../models/assignment-participant.model';
import { AssignmentContactingParticipantsResultModel } from '../models/assignment-contacting-participants-result.model';
import { AssignmentParticipantContactingPostModel } from '../models/assignment-participants-contacting.model';

@Injectable({
  providedIn: 'root'
})
export class AssignmentsParticipantService {

  constructor(private httpClient: HttpClient) { }

  getAssignmentParticipants(assignmentId: number): Observable<AssignmentParticipantModel[]> {
    return this.httpClient.get<AssignmentParticipantModel[]>(`${environment.api}/assignments/${assignmentId}/participants`);
  }

  sendMessageToAssignmentParticipants(assignmentId: number, assignmentContactingParticipantsModel: AssignmentParticipantContactingPostModel): Observable<AssignmentContactingParticipantsResultModel> {
    return this.httpClient.post<AssignmentContactingParticipantsResultModel>(`${environment.api}/assignments/${assignmentId}/participants/contactParticipants`, assignmentContactingParticipantsModel);
  }
} 
