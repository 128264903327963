<div class="card">
    <div class="card-header d-flex justify-content-between" [ngClass]="{'border-0': !isOpen}" (click)="toggle()"
        role="button">
        <p class="fw-bold mb-0">{{ cardTitle }}</p>
        <button class="btn btn-link p-0">
            <i class="fa-solid fa-lg" [ngClass]="isOpen ? 'fa-angle-up' : 'fa-angle-down'"></i>
        </button>
    </div>
    <div *ngIf="isOpen">
        <ng-content></ng-content>
    </div>
</div>