import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PmtEssentialsModule } from '@premotec/pmt-essentials';
import { PmtAgGridModule } from '@premotec/pmt-ag-grid';
import { PmtBootstrapModule } from '@premotec/pmt-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { PmtDatepickerModule } from '@premotec/pmt-datepicker';
import { EmptyComponent } from './empty/empty.component';
import { DatePipe } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AgGridModule } from 'ag-grid-angular';
import { NgPipesModule } from 'ngx-pipes';
import { TranslocoModule } from '@jsverse/transloco';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { PageDividerComponent } from './components/page-divider/page-divider.component';
import { AssignmentListComponent } from '../assignments/assignments-list/assignments-list.component';
import { RouterModule } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SponsorsComponent } from './components/sponsors/sponsors.component';
import { CollapsibleCardComponent } from './components/collapsible-card/collapsible-card.component';
import { ImpersonationIndicatorComponent } from './components/impersonation-indicator/impersonation-indicator.component';

const defaultModules = [CommonModule, FormsModule, ReactiveFormsModule];

const customModules = [
  ToastrModule.forRoot(),
  NgSelectModule,
  TranslocoModule,
  AgGridModule,
  NgPipesModule,
];

const pmtModules = [
  PmtDatepickerModule,
  PmtEssentialsModule,
  PmtBootstrapModule,
  PmtAgGridModule,
];

@NgModule({
  imports: [
    defaultModules,
    customModules, 
    pmtModules, 
    RouterModule, 
  ],
  declarations: [
    EmptyComponent,
    UnderConstructionComponent,
    ImpersonationIndicatorComponent,
    PageDividerComponent,
    AssignmentListComponent,
    SponsorsComponent,
    CollapsibleCardComponent,
  ],
  exports: [
    defaultModules,
    customModules,
    pmtModules,
    ReactiveFormsModule,
    TooltipModule,
    EmptyComponent,
    TabsModule,
    BsDropdownModule,
    UnderConstructionComponent,
    ImpersonationIndicatorComponent,
    PageDividerComponent,
    AssignmentListComponent,
    SponsorsComponent,
    CollapsibleCardComponent
  ],
  providers: [DatePipe, BsModalService],
})
export class SharedModule { }
