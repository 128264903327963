<pmt-action-modal [title]="'assignments.communication.feedback' | transloco" submitButtonIconCode="''"
    [cancelButtonText]="'modal.cancelButton' | transloco" [submitButtonText]="'modal.sendButton' | transloco"
    (submitted)="saveForm()" class="custom-modal-limiter">
    <div class="form-group d-flex flex-column gap-1"
        [ngClass]="{'border border-2 border-danger': feedbackResponse?.isSuccess == false}">
        <form [formGroup]="feedbackForm">
            <textarea formControlName="feedback" class="form-control" rows="3"></textarea>
        </form>
    </div>
    <ng-container *ngIf="!feedbackResponse?.isSuccess">
        <p class="text-danger">{{ feedbackResponse?.errorMessage }}</p>
    </ng-container>
</pmt-action-modal>