<pmt-action-modal [title]="'assignments.exchangeAssignments.title' | transloco" [onlyCancel]="isCloseOnly"
    [cancelButtonText]="isCloseOnly ? ('modal.closeButton' | transloco) : ('modal.cancelButton' | transloco)"
    [submitButtonText]="selectedTrainerName ? ('assignments.exchangeAssignments.submitButtonTrainer' | transloco) + ' ' + selectedTrainerName : ('assignments.exchangeAssignments.submitButtonNoTrainer' | transloco)"
    [submitButtonIconCode]="''" (submitted)="setSubstitute()">
    <div class="d-flex flex-column gap-2">
        <!-- Custom max height, so dropdown is always visbible -->
        <div class="d-flex flex-column gap-3" style="max-height: calc(100vh - 300px); overflow-x: auto;">
            <div *ngFor="let assignment of exchangeAssignments; let i = index">
                <div class="text-decoration-none text-dark w-100 shadow-sm p-3 bg-white d-block" [ngClass]="{
          'border border-2 border-success': assignment.isSuccess == true,
          'border border-2 border-danger': assignment.isSuccess == false
        }">
                    <div class="d-flex align-items-center gap-1 w-100">
                        <div class="flex-grow-1 overflow-hidden text-start">
                            <div class="d-flex align-items-center">
                                <i class="fa-regular fa-calendar-days me-1"></i>
                                <span class="mb-0 fs-6 fw-lighter text-start text-nowrap">
                                    {{ assignment.assignment.from| date: "EE dd.MM.yy | HH:mm" }}
                                </span>
                                <span> - </span>
                                <span class="mb-0 fs-6 fw-lighter text-start text-nowrap">
                                    <ng-container
                                        *ngIf="isAssignmentOnSameDay(assignment.assignment.from, assignment.assignment.to)">
                                        {{ assignment.assignment.to | date: "HH:mm" }}
                                    </ng-container>
                                    <ng-template
                                        *ngIf="!isAssignmentOnSameDay(assignment.assignment.from, assignment.assignment.to)">
                                        {{ assignment.assignment.to | date: "EE dd.MM.yy | HH:mm" }}
                                    </ng-template>
                                </span>
                            </div>
                            <div class="mb-0 fw-bold text-truncate">
                                {{ assignment.assignment.sportName }} | {{ assignment.assignment.title }}
                            </div>
                            <p class="mb-0 fs-6">{{ assignment.assignment.room }}</p>
                        </div>
                    </div>
                    <!-- Individual Response Message -->
                    <div class="text-danger" *ngIf="assignment.isSuccess == false">
                        {{ assignment.errorMessage }}
                    </div>
                </div>
            </div>
        </div>
        <form [formGroup]="exchangeAssignmentsForm">
            <div>
                <ng-select formControlName="selectedTrainer" [required]="true" [searchable]="true" [clearable]="true"
                    [disabled]="(trainers$ | async) == null"
                    [placeholder]="'assignments.exchangeAssignments.searchPerson' | transloco">
                    <ng-option [value]="0" *ngIf="isVacacyAllowed">
                        {{'assignments.exchangeAssignments.vacant' | transloco}}
                    </ng-option>
                    <ng-option [disabled]="true" *ngIf="isVacacyAllowed">----------</ng-option>
                    <ng-option *ngFor="let trainer of trainers$ | async" [value]="trainer.id"
                        [disabled]="trainer.isSportMissing">
                        {{(trainer.firstName + ' ' + trainer.lastName).trim()}}
                        <span *ngIf="trainer.isSportMissing">
                            {{'assignments.exchangeAssignments.missingSports' | transloco}}
                        </span>
                    </ng-option>
                </ng-select>
            </div>
        </form>
        <p class="warning text-secondary p-0 m-0" *ngIf="isMoreThenOneSportSelected">
            {{'assignments.exchangeAssignments.requiredSports' | transloco}}
        </p>
    </div>
</pmt-action-modal>