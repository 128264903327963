import { Component, OnInit } from '@angular/core';
import { NewsModel } from '../models/news.model';
import { ActivatedRoute } from '@angular/router';
import { HeaderStateService } from '../../main/headers/services/header-state.service';
import { TranslocoService } from '@jsverse/transloco';
import { NewsService } from '../services/news.service';


@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrl: './news-details.component.scss'
})
export class NewsDetailsComponent implements OnInit {
  newsId!: string;
  newsModel: NewsModel | null = null;

  constructor(
    private newsService: NewsService,
    private route: ActivatedRoute,
    private headerStateService: HeaderStateService,
    private translocoService: TranslocoService
  ) {
    this.headerStateService.setDetailHeader("News", '', '/home', '');
  }

  ngOnInit(): void {
    this.newsId = this.getNewsId();
    if (this.newsId) {
      this.fetchNewsDetails(this.newsId);
    }
  }

  private getNewsId(): string {
    return this.route.snapshot.paramMap.get('id') || '';
  }

  private fetchNewsDetails(newsId: string): void {
    this.newsService.getNewsDetails(newsId)
      .subscribe({
        next: (news) => {
          this.newsModel = news;
          this.headerStateService.setDetailHeader(
            this.translocoService.translate('news.title'),
            this.translocoService.translate('news.description'),
            '/home',
            ''
          );
        },
        error: (err) => {
          console.error('Error fetching news details:', err);
        }
      });
  }
}
