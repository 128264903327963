import { Component, Input } from '@angular/core';
import { AssignmentParticipantsStateService } from '../../services/assignments-participants-state.service';
import { AssignmentDetailsStateService } from '../../services/assignment-details-state.service';

@Component({
  selector: 'app-assignment-participant',
  templateUrl: './assignment-participants.component.html',
  styleUrl: './assignment-participants.component.scss'
})
export class AssignmentParticipantsComponent {
  @Input({ required: true }) assignmentId!: number;

  constructor(
    protected assignmentParticipantStateService: AssignmentParticipantsStateService,
    protected assignmentDetailsStateService: AssignmentDetailsStateService
  ) { }
}
