import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AssignmentsFilterModel } from "../models/assignments-filter.model";
import { TranslocoService } from "@jsverse/transloco";

@Injectable()
export class AssignmentsFilterStateService {

    public dateItems: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
    public searchTextItems: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
    public skillLevelItems: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
    public weekdaysItems: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);

    public isAnyFilterDisplayed = false;
    public isAnyFilterDisplayedEvent = new BehaviorSubject<boolean>(false);

    constructor(private translocoService: TranslocoService) { }

    public resetAllItems(): void {
        this.dateItems.next([]);
        this.searchTextItems.next([]);
        this.skillLevelItems.next([]);
        this.weekdaysItems.next([]);
    }

    public deleteSearchFilter(filterModelSubject?: BehaviorSubject<AssignmentsFilterModel | null>): void {
        if (!filterModelSubject) return;

        var filterModel = filterModelSubject.value;
        if (!filterModel) return;

        filterModel.searchText = null;

        filterModelSubject.next(filterModel);
    }

    public deleteSkillLevelFilter(filterModelSubject?: BehaviorSubject<AssignmentsFilterModel | null>): void {
        if (!filterModelSubject) return;

        var filterModel = filterModelSubject.value;
        if (!filterModel) return;

        filterModel.skillLevels = null;

        filterModelSubject.next(filterModel);
    }

    public deleteDateRangeFilter(filterModelSubject?: BehaviorSubject<AssignmentsFilterModel | null>): void {
        if (!filterModelSubject) return;

        var filterModel = filterModelSubject.value;
        if (!filterModel) return;

        filterModel.dateFrom = null;
        filterModel.dateTo = null;

        filterModelSubject.next(filterModel);
    }

    public deleteWekdaysFilter(filterModelSubject?: BehaviorSubject<AssignmentsFilterModel | null>): void {
        if (!filterModelSubject) return;

        var filterModel = filterModelSubject.value;
        if (!filterModel) return;

        filterModel.isWeekdayFilterActive = false;
        filterModel.weekdays.isMondayActive = true;
        filterModel.weekdays.isTuesdayActive = true;
        filterModel.weekdays.isWednesdayActive = true;
        filterModel.weekdays.isThursdayActive = true;
        filterModel.weekdays.isFridayActive = true;
        filterModel.weekdays.isSaturdayActive = true;
        filterModel.weekdays.isSundayActive = true;

        filterModelSubject.next(filterModel);
    }


    public updateIsAnyFilterDisplayed(filterModel: AssignmentsFilterModel | null): void {
        this.isAnyFilterDisplayed = this.getIsAnyFilterDisplayed(filterModel);
        this.isAnyFilterDisplayedEvent.next(this.isAnyFilterDisplayed);
    }

    public getIsAnyFilterDisplayedEvent(): BehaviorSubject<boolean> {
        this.isAnyFilterDisplayedEvent = new BehaviorSubject<boolean>(this.isAnyFilterDisplayed);
        return this.isAnyFilterDisplayedEvent;
    }

    public getIsAnyFilterDisplayed(filterModel: AssignmentsFilterModel | null): boolean {
        if (filterModel == null) return false;

        if (this.dateItems.value?.length) return true;
        if (this.searchTextItems.value?.length) return true;
        if (this.skillLevelItems.value?.length) return true;
        if (this.weekdaysItems.value?.length) return true;

        return false;
    }

    public updateFilterDisplay(filterModel: AssignmentsFilterModel | null): void {
        if (filterModel == null) {
            this.dateItems.next(null);
            this.weekdaysItems.next(null);

            this.searchTextItems.next(null);
            this.skillLevelItems.next(null);

            return;
        }

        this.dateItems.next(this.getDateRangeItems(filterModel));
        this.weekdaysItems.next(this.getWeekdayItems(filterModel));

        this.searchTextItems.next(this.getSeatchTextItems(filterModel));
        this.skillLevelItems.next(this.getSkillLeveTextItems(filterModel));
    }

    private getDateRangeItems(filterModel: AssignmentsFilterModel): string[] {
        if (filterModel.dateFrom && filterModel.dateTo) return [`${filterModel.dateFrom} - ${filterModel.dateTo}`]
        if (filterModel.dateFrom) return [`${filterModel.dateFrom} - ...`]
        if (filterModel.dateTo) return [`... - ${filterModel.dateTo}`]

        return [];
    }

    private getSeatchTextItems(filterModel: AssignmentsFilterModel): string[] {
        if (filterModel.searchText == null || filterModel.searchText == '') return [];
        return [filterModel.searchText]
    }

    private getSkillLeveTextItems(filterModel: AssignmentsFilterModel): string[] {
        if (filterModel.skillLevels == null || !filterModel.skillLevels?.length) return [];
        return filterModel.skillLevels.flat().map(({ name }) => name)
    }

    private getWeekdayItems(filterModel: AssignmentsFilterModel): string[] {

        if (!filterModel.isWeekdayFilterActive) return [];
        if (this.getIsFullWeekActive(filterModel)) return [this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.fullWeek')]

        let weekdayItems: string[] = [];

        if (filterModel.weekdays.isMondayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.monday'));
        if (filterModel.weekdays.isTuesdayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.tuesday'));
        if (filterModel.weekdays.isWednesdayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.wednesday'));
        if (filterModel.weekdays.isThursdayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.thursday'));
        if (filterModel.weekdays.isFridayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.friday'));
        if (filterModel.weekdays.isSaturdayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.saturday'));
        if (filterModel.weekdays.isSundayActive) weekdayItems.push(this.translocoService.translate('assignments.assignmentsFilterModal.weekdays.sunday'));

        return weekdayItems;
    }

    private getIsFullWeekActive(filterModel: AssignmentsFilterModel): boolean {
        if (!filterModel.weekdays.isMondayActive) return false;
        if (!filterModel.weekdays.isTuesdayActive) return false;
        if (!filterModel.weekdays.isWednesdayActive) return false;
        if (!filterModel.weekdays.isThursdayActive) return false;
        if (!filterModel.weekdays.isFridayActive) return false;
        if (!filterModel.weekdays.isSaturdayActive) return false;
        if (!filterModel.weekdays.isSundayActive) return false;

        return true;
    }

}