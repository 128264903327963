<div class="d-flex flex-column" style="max-height: 100%;">
    <div class="pb-2 d-flex flex-md-row flex-column">
        <div>
            <app-filter-display [filterModelSubject]="assignmentsStateService.openAssignmentsFilterSubject">
            </app-filter-display>
        </div>
        <div class="flex-grow-1"></div>
        <div>
            <button (click)="openTakeoverModal()" class="btn btn-primary w-100"
                [disabled]="selectedAssignments.length === 0">
                {{ selectedAssignments.length! > 0 ? selectedAssignments.length : 0 }}
                {{ "assignments.openAssignments.title" | transloco }}
            </button>
        </div>
    </div>
    <div class="flex-grow-1 overflow-y-auto">
        <app-assignments-list [eventType]="assignmentEventType.Checkbox"
            [assignmentSubject]="assignmentStateService.openAssignmentsSubject"
            (assignmentsSelected)="onAssignmentSelected($event)">
        </app-assignments-list>
    </div>
</div>