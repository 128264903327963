import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidators {

    public static hasValidator(form: FormGroup, controlName: string, validatorName: string): boolean {
        const control = form.get(controlName);
        if (control == null) return false;

        const validators = control.validator!(control);
        if (validators == null) return false;

        return validators.hasOwnProperty(validatorName);
    }


    public static greaterThan(startControl: AbstractControl): ValidatorFn {
        return (endControl: AbstractControl): ValidationErrors | null => {
            const startDate: Date = startControl.value;
            const endDate: Date = endControl.value;
            if (!startDate || !endDate) {
                return null;
            }
            if (startDate >= endDate) {
                return { greaterThan: true };
            }
            return null;
        };
    }

    public static greaterOrEqualThan(startControl: AbstractControl): ValidatorFn {
        return (endControl: AbstractControl): ValidationErrors | null => {
            const startDate: Date = startControl.value;
            const endDate: Date = endControl.value;
            if (!startDate || !endDate) {
                return null;
            }
            if (startDate > endDate) {
                return { greaterOrEqualThan: true };
            }
            return null;
        };
    }
}