<!-- Loading -->
<ng-container *ngIf="(assignmenParticipantStateService.assignmentParticipantsSubject| async) == null">
    <div class="text-center">
      <span><i class="fa-solid fa-spinner fa-spin"></i></span>
    </div>
  </ng-container>

  <ng-container *ngIf="(assignmenParticipantStateService.assignmentParticipantsSubject| async) != null">
    <div class="d-flex flex-column">
        <button (click)="openModal()" class="btn btn-primary" [disabled]="true">
            {{'assignments.skills.openModal' | transloco}}
        </button>
    </div>
  </ng-container>
