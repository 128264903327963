export const environment = {
    production: false,
    staging: true,
    api: 'https://test.api.staff.asvz.ch/api',
    loginPage: 'https://test.auth.asvz.ch',
    adminUrl: 'https://test.schalter.asvz.ch/tn/staff',

    identity: {
        client_id: '437efaf4-589b-4eab-9e12-ecb899f7c724',
        authority: 'https://test.auth.asvz.ch',
        redirect_uri: 'https://test.staff.asvz.ch/assets/oidc-login-redirect.html',
        scope: 'openid profile stage.staff-api stage.tn-auth',
        response_type: 'id_token token',
        post_logout_redirect_uri: 'https://test.staff.asvz.ch/?postLogout=true',
        silent_redirect_uri: 'https://test.staff.asvz.ch/assets/silent-redirect.html',
    },

    impersonation: {
        impersonationCookieUserIdKey: 'StaffImpersonationUserId',
        impersonationCookieDomain: 'test.staff.asvz.ch',
        allowedDomains: ['https://test.api.staff.asvz.ch'],
        endUrl: "https://test.schalter.asvz.ch/tn/staff/admin/impersonation"
    },

    baseUrl: 'https://test.staff.asvz.ch',
    onlineDeskUrl: 'https://test.schalter.asvz.ch/tn'
};
