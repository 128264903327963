import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthCallbackComponent } from '../security/auth-callback/auth-callback.component'
import { HomeComponent } from '../home/home.component'
import { InboxComponent } from '../inbox/inbox.component'
import { KnowHowComponent } from '../know-how/know-how.component'
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component'
import { PageServerErrorComponent } from './errors/page-server-error/page-server-error.component'
import { PageNoPermissionComponent } from './errors/page-no-permission/page-no-permission.component'
import { LogoutComponent } from '../security/logout/logout.component'
import { RootComponent } from './root/root.component'
import { HelpComponent } from './information/help/help.component'
import { FaqComponent } from './information/faq/faq.component'
import { ImprintComponent } from './information/imprint/imprint.component'
import { AuthGuard } from '../security/guards'
import { NewsDetailsComponent } from '../news/news-details/news-details.component'
import { AdminAuthGuard } from '../security/guards/admin-auth-guard.service'
import { StaffAuthGuard } from '../security/guards/staff-auth-guard.service'

const routes: Routes = [
    {
        path: '',
        component: RootComponent,
        children: [
            // protected routs
            {
                path: 'assignments',
                loadChildren: () => import('../assignments/assignment.module').then((m) => m.AssignmentModule),
                canActivate: [StaffAuthGuard],
            },

            {
                path: 'inbox',
                component: InboxComponent,
                canActivate: [StaffAuthGuard],
            },
            {
                path: 'know-how',
                component: KnowHowComponent,
                canActivate: [StaffAuthGuard],
            },
            {
                path: 'profile',
                loadChildren: () => import('../profile/profile.module').then((m) => m.ProfileModule),
                canActivate: [StaffAuthGuard],
            },
            {
                path: 'news/:id',
                component: NewsDetailsComponent,
                canActivate: [StaffAuthGuard]
            },
            // ToDo: Remove once all links are updated (Local and OnlineDesk)
            { path: 'impersonation', redirectTo: 'admin/impersonation' },
            {
                path: 'admin',
                loadChildren: () => import('../admin/admin.module').then((m) => m.AdminModule),
                canActivate: [AdminAuthGuard],
            },

            // public routs
            { path: 'login', component: AuthCallbackComponent, },
            { path: 'help', component: HelpComponent, },
            { path: 'faq', component: FaqComponent, },
            { path: 'imprint', component: ImprintComponent, },
            { path: 'notFound', component: PageNotFoundComponent },
            { path: 'error', component: PageServerErrorComponent },
            { path: 'forbidden', component: PageNoPermissionComponent },
            { path: 'logout', component: LogoutComponent },
            { path: 'home', component: HomeComponent, },
            { path: '**', redirectTo: 'home' },
        ],
    },
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MainRoutingModule { }
