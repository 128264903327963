import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignmentDetailsStateService } from '../../../services/assignment-details-state.service';
import { format } from 'date-fns';
import { AssignmentDetailsModel } from '../../../models/assignment-details.model';
import { AssignmentParticipantContactingPostModel } from '../../../models/assignment-participants-contacting.model';
import { AssignmentsParticipantService } from '../../../services/assignments-participants.service';

@Component({
  selector: 'app-assignment-participants-contacting-modal',
  templateUrl: './assignment-participants-contacting-modal.component.html',
  styleUrl: './assignment-participants-contacting-modal.component.scss'
})
export class AssignmentContactParticipantsModalComponent implements OnInit {
  assignmentContactingParticipantsModel!: AssignmentParticipantContactingPostModel
  participantContactForm!: FormGroup;
  assignmentId!: number;
  subjectText: string = "";
  errorMessage: string | null = null;
  editorModule: any;

  constructor(
    private formBuilder: FormBuilder,
    private assignmentParticipantService: AssignmentsParticipantService,
    private bsModalService: BsModalService,
    private assignmentDetailsStateService: AssignmentDetailsStateService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.setModules();
  }

  public saveForm() {
    if (this.participantContactForm.valid) {
      this.sendMessageToParticipants();
    }
  }

  private sendMessageToParticipants(): void {
    this.errorMessage = null;

    this.assignmentContactingParticipantsModel = {
      subject: this.participantContactForm.value.subject,
      message: this.participantContactForm.value.message,
      isSendCopy: this.participantContactForm.value.sendCopy,
    };

    this.assignmentParticipantService.sendMessageToAssignmentParticipants(this.assignmentId, this.assignmentContactingParticipantsModel)
      .subscribe({
        next: () => {
          this.bsModalService.hide();
        },
        error: (error) => {
        }
      });
  }

  private initForm(): void {
    this.assignmentDetailsStateService.assignmentDetails.subscribe({
      next: (assignment) => {

        if (assignment) {
          this.subjectText = this.createMailSubject(assignment);
          this.participantContactForm = this.formBuilder.group({
            subject: ['', Validators.required],
            message: ['', Validators.required],
            sendCopy: [false]
          });
        }
      }
    });
  }

  private createMailSubject(assignment: AssignmentDetailsModel): string {

    const lessonTitle = assignment?.title;
    const fromDate = format(new Date(assignment.from), 'dd.MM.yy');
    const time = `${format(new Date(assignment.from), "hh:mm")} - ${format(new Date(assignment.to), "hh:mm")}`;

    return `ASVZ: ${lessonTitle}, ${fromDate}, ${time}`;
  }

  private setModules() {
    this.editorModule = {
      toolbar: [
        [{ 'header': 1 }, { 'header': 2 }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link']
      ]
    };
  }
}
